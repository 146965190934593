import React from "react";
import { AiFillHome } from "react-icons/ai";
import { BiSolidUserDetail } from "react-icons/bi";
import { BsTags } from "react-icons/bs";
import { FaEnvelopeOpenText, FaUser, FaUserPlus } from "react-icons/fa";
import { GiFamilyHouse } from "react-icons/gi";
import { ImProfile } from "react-icons/im";
import { IoIosPaper } from "react-icons/io";
import { IoBagAddOutline } from "react-icons/io5";
import { MdGroups, MdManageAccounts, MdOutlineWarehouse } from "react-icons/md";
import { PiTreeStructureFill } from "react-icons/pi";
import {
  RiArrowDownSFill,
  RiArrowUpSFill,
  RiFunctionAddLine,
  RiFunctionLine,
  RiHistoryFill,
  RiUserAddLine,
} from "react-icons/ri";

export const SidebarData = () => {
  return [
    {
      title: "Dashboard",
      path: "/",
      icon: <AiFillHome />,
      id: 2,
    },
    {
      title: "Warehouse Manage",
      path: "/warehouse_manage",
      icon: <GiFamilyHouse />,
      iconClosed: <RiArrowDownSFill />,
      iconOpened: <RiArrowUpSFill />,
      id: 2,
      subNav: [
        {
          title: "Add Equipment",
          path: "/warehouse_manage/add_product",
          icon: <IoBagAddOutline />,
          id: 2,
        },
        {
          title: "Add Category",
          path: "/warehouse_manage/add_category",
          icon: <RiFunctionAddLine />,
          id: 2,
        },
        {
          title: "Category",
          path: "/warehouse_manage/category",
          icon: <RiFunctionLine />,
          id: 2,
        },
        {
          title: "Equipment",
          path: "/warehouse_manage/product",
          icon: <BsTags />,
          id: 2,
        },

        {
          title: "Create Warehouse",
          path: "/warehouse_manage/create_warehouse",
          icon: <MdOutlineWarehouse />,
          id: 2,
        },
      ],
    },
    {
      title: "Group Create",
      path: "/group_create",
      icon: <MdGroups />,
      iconClosed: <RiArrowDownSFill />,
      iconOpened: <RiArrowUpSFill />,
      id: 2,
      subNav: [
        {
          title: "All Group",
          path: "/group_create/all_group",
          icon: <MdGroups />,
          id: 2,
        },
        {
          title: "Request",
          path: "/group_create/request",
          icon: <RiUserAddLine />,
          id: 2,
        },
        {
          title: "History",
          path: "/group_create/history",
          icon: <RiHistoryFill />,
          id: 2,
        },
      ],
    },
    {
      title: "Add Users",
      path: "/add_users",
      icon: <FaUserPlus />,
      iconClosed: <RiArrowDownSFill />,
      iconOpened: <RiArrowUpSFill />,
      id: 2,

      subNav: [
        {
          title: "Users List",
          path: "/add_users/users_list",
          icon: <FaUser />,
          cName: "sub-nav",
          id: 2,
        },
        {
          title: "Invited List",
          path: "/add_users/invited_list",
          icon: <BiSolidUserDetail />,
          cName: "sub-nav",
          id: 2,
        },
      ],
    },

    {
      title: "Request Equipment",
      path: "/request_equipment",
      icon: <FaEnvelopeOpenText />,
      id: 2,
    },
    {
      title: "Unit Profile",
      path: "/unite_profile",
      icon: <ImProfile />,
      iconClosed: <RiArrowDownSFill />,
      iconOpened: <RiArrowUpSFill />,
      id: 2,
      subNav: [
        {
          title: "Request Unit",
          path: "/unite_profile/req_unit",
          icon: <RiUserAddLine />,
          id: 2,
        },
      ],
    },
    {
      title: "Hierarchy",
      path: "/hierarchy",
      icon: <PiTreeStructureFill />,
      id: 2,
    },
    {
      title: "Manage Role",
      path: "/manage_role",
      icon: <MdManageAccounts />,
      iconClosed: <RiArrowDownSFill />,
      iconOpened: <RiArrowUpSFill />,
      id: 2,
      subNav: [
        {
          title: "Invited Users",
          path: "/manage_role/invited_users",
          icon: <IoIosPaper />,
          cName: "sub-nav",
          id: 2,
        },
      ],
    },
  ];
};
