import React, { useState } from "react";
import styled from "styled-components";
import {
  useRoleList,
  useUnitList,
  useUnitUserList,
  useUserSelectedList,
} from "../../utils/useAppUtils";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  assignUserToUnitAction,
  moveUserToUnitAction,
  setSelectedUserId,
} from "../../redux/users/action";
import { AssignNowButton, BlackBorderButton } from "../Global/GlobalButtons";
import { ProfileImage, RoleSelector, TableWrapper } from "../warehouse/Product";
import { Container, Modal } from "react-bootstrap";
import GlobalSearch from "../Global/GlobalSearch";
import { toast } from "react-toastify";
import userImage from "../../Assets/profile.png";

const Listofusers = () => {
  const usersSelect = useUserSelectedList();
  const role = useRoleList();
  const groups = useUnitList();

  const [selectedRole, setSelectedRole] = useState("user");
  const [selectedUserDetails, setSelectedUserDetails] = useState([]);
  const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);
  const selectedUnitId = useSelector((state) => state?.users?.selectedUnitId);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showUnit, setShowUnit] = useState(false);
  const memenerlsitfetch = useUnitUserList();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [showAssign, setShowAssign] = useState(false);
  const [currentPageUser, setCurrentPageUser] = useState(1);
  const itemsPerPageUser = 6;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = memenerlsitfetch.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(memenerlsitfetch.length / itemsPerPage);
  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleNavigateToQuipemtn = (id) => {
    dispatch(setSelectedUserId(id));
    navigate(`/userlist-equipment/${id}`);
  };

  const handleClose = () => {
    setShowAssign(false);
    setShowUnit(false);
    setIsCheckboxSelected(false);
    setSelectedUserDetails([]);
  };

  const handleSelectChange = (rowIndex, itemType, isChecked) => {
    if (itemType === "user") {
      if (
        filteredPaginatedUsersSelect &&
        rowIndex >= 0 &&
        rowIndex < filteredPaginatedUsersSelect.length
      ) {
        const user = filteredPaginatedUsersSelect[rowIndex];

        setSelectedUsers((prevSelectedUsers) => {
          if (isChecked) {
            return [...prevSelectedUsers, user.user_id];
          } else {
            return prevSelectedUsers.filter(
              (selectedUserId) => selectedUserId !== user.user_id
            );
          }
        });
      }
    }
  };
  const getPaginatedData = (data, currentPage, itemsPerPage) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  };
  const filteredPaginatedUsersSelect = getPaginatedData(
    usersSelect.filter(
      (item) => selectedRole === "" || item.role === selectedRole
    ),  
    currentPageUser,
    itemsPerPageUser
  );
  const handleShowAssign = () => {
    setShowAssign(true);
  };
  const handleAssignClick = () => {
    if (!selectedUnitId) {
      toast.error("No group selected for assignment.");
      return;
    }

    if (selectedUsers.length === 0) {
      toast.error("No users selected for assignment.");
      return;
    }

    console.log("Selected Users' User IDs:", selectedUsers);  

    dispatch(
      assignUserToUnitAction({
        userids: selectedUsers,  
        unit_id: selectedUnitId,
        userid_role: selectedRole, 
      })
    );

    setSelectedUsers([]);
    setShowAssign(false);
    setIsCheckboxSelected(false);
    toast.success("Assignment successful and selections cleared.");
  };

  const handleMoveNowClick = () => {
    if (!isCheckboxSelected || !selectedUserDetails) {
      toast.error("Please select a user before proceeding with 'Move Now'.");
      return;
    }
    setShowUnit(true);
    console.log("Selected user details:", selectedUserDetails);
    setIsCheckboxSelected(false);
  };

  const handleAssignNow = (groupId) => {
    if (!selectedUserDetails?.id || !selectedUserDetails?.unit_id) {
      toast.error("Please select both a user and a group for assignment.");
      return;
    }
    console.log("Assigning user:", selectedUserDetails);
    console.log("Group ID:", groupId);
    const userDetails = {
      userid: selectedUserDetails.id,  
      unit_id: selectedUnitId,
      role: selectedUserDetails.role,  
    };

    console.log("Dispatching moveUserToGroupAction with details:", userDetails);

    dispatch(moveUserToUnitAction(userDetails));
    handleClose();
    setIsCheckboxSelected(false);
    setSelectedUserDetails([]);
  };
  const handleSelectChangeUser = (index, isChecked) => {
    const user = currentItems[index];
    setSelectedUserDetails(
      isChecked
        ? {
            id: user.id,
            role: user.role,
          }
        : null
    );
    setIsCheckboxSelected(isChecked);
  };

  const handleSelectChangeGroup = (rowIndex, isChecked) => {
    if (groups && rowIndex >= 0 && rowIndex < groups.length) {
      const group = groups[rowIndex];
      setSelectedUserDetails((prev) => ({
        ...prev,
        unit_id: isChecked ? group.unit_id : null,
      }));
    }
  };
  return (
    <Containers>
      <FilterSection>
        <FilterLabel>Filter By:</FilterLabel>
        <Button onClick={handleShowAssign}>Add User</Button>
      </FilterSection>
      <Table>
      <thead>
  <TableRow>
    <TableHeader>Select</TableHeader>
    <TableHeader>SL No</TableHeader>
    <TableHeader>Member Name</TableHeader>
    <TableHeader>Position</TableHeader>
    <TableHeader>Unit Name</TableHeader>
    <TableHeader>Move Now</TableHeader>
    <TableHeader>Change Role</TableHeader>
    <TableHeader>Remove</TableHeader>
  </TableRow>
</thead>
<tbody>
  {currentItems.length === 0 ? (
    <tr>
      <td colSpan="8">
        <div className="no-data-container">
          <div className="icon">🚫</div>
          <h2>No Data Available</h2>
          <div className="line"></div>
          <p>We couldn’t find any data to display. Please try again later.</p>
        </div>
      </td>
    </tr>
  ) : (
    currentItems.map((item, index) => (
      <TableRow key={item.id}>
        <TableCell>
          <input
            type="checkbox"
            checked={isCheckboxSelected[item.id]}
            onChange={(e) =>
              handleSelectChangeUser(index, e.target.checked)
            }
          />
        </TableCell>
        <TableCell>
          {String(indexOfFirstItem + index + 1).padStart(2, "0")}
        </TableCell>
        <TableCell
          onClick={() => handleNavigateToQuipemtn(item.id)}
          style={{ cursor: "pointer", color: "blue" }}
        >
          {item.name || "No Name"}
        </TableCell>
        <TableCell>{item.role}</TableCell>
        <TableCell>{item.unit_name}</TableCell>
        <TableCell>
          <BlackBorderButton onClick={() => handleMoveNowClick(index)}>
            Move Now
          </BlackBorderButton>
        </TableCell>
        <TableCell>
          <Dropdown>
            <option>Soldier</option>
            <option>Manager</option>
            <option>Group Admin</option>
          </Dropdown>
        </TableCell>
        <TableCell>
          <DeleteIcon onClick={() => handleDelete(item.id)}>
            🗑️
          </DeleteIcon>
        </TableCell>
      </TableRow>
    ))
  )}
</tbody>

      </Table>

      <Pagination>
        <PaginationButton
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          Previous
        </PaginationButton>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <PaginationButton
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </PaginationButton>
      </Pagination>
      <>
        <Modal show={showUnit} onHide={handleClose} size="xl" centered>
          <Modal.Header closeButton>
            <div>
              <GlobalSearch search="Search by Group" />
            </div>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <TableWrapper>
                <Table>
                  <thead>
                    <TableRow>
                      <TableHeader></TableHeader>
                      <TableHeader>Profile Image</TableHeader>
                      <TableHeader>ID</TableHeader>
                      <TableHeader>Unit Name</TableHeader>
                      <TableHeader>Role</TableHeader>
                      <TableHeader>Action</TableHeader>
                    </TableRow>
                  </thead>
                  <tbody>
                    {groups.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ minWidth: "60px" }}>
                          <input
                            type="checkbox"
                            checked={
                              selectedUserDetails?.unit_id === item.unit_id
                            }
                            onChange={(event) =>
                              handleSelectChangeGroup(
                                index,
                                event.target.checked
                              )
                            }
                          />
                        </TableCell>
                        <TableCell style={{ minWidth: "60px" }}>
                          <ProfileImage
                            src={item.image || userImage}
                            alt={item.unit_name}
                          />
                        </TableCell>
                        <TableCell>{item.unit_id || "-"}</TableCell>
                        <TableCell>{item.unit_name}</TableCell>
                        <TableCell>{item.role || "-"}</TableCell>
                        <TableCell>
                          <AssignNowButton
                            onClick={() => handleAssignNow(item.unit_id)}
                          >
                            {" "}
                            Assign now
                          </AssignNowButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </tbody>
                </Table>
              </TableWrapper>
              <Pagination
                currentPage={currentPageUser}
                totalItems={
                  usersSelect.filter((item) => item.role === selectedRole)
                    .length
                }
                itemsPerPage={itemsPerPageUser}
                onPageChange={(page) => setCurrentPageUser(page)}
              />
            </Container>
          </Modal.Body>
        </Modal>
      </>
      <>
        <Modal show={showAssign} onHide={handleClose} size="xl" centered>
          <Modal.Header closeButton>
            <div>
              <GlobalSearch search="Search by Name,Id,Group,Unit" />
            </div>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <RoleSelector>
                {" "}
                <b>Select</b>
                {role.length
                  ? role.map((roleItem) => (
                      <BlackBorderButton
                        key={roleItem.role} // Use `roleItem.role` as the key, assuming `roleItem` is an object with a `role` key
                        className={
                          selectedRole === roleItem.role ? "active" : ""
                        }
                        onClick={() => handleRoleSelect(roleItem.role)} // Pass roleItem.role instead of roleItem
                      >
                        {roleItem.role || "unknown"}{" "}
                        {/ Render roleItem.role /}
                      </BlackBorderButton>
                    ))
                  : "No roles available"}
              </RoleSelector>
              <TableWrapper>
                <Table>
                  <thead>
                    <TableRow>
                      <TableHeader></TableHeader>
                      <TableHeader>Profile Image</TableHeader>
                      <TableHeader>Username</TableHeader>
                      <TableHeader>Profile</TableHeader>
                      <TableHeader>ID</TableHeader>
                      <TableHeader>Group</TableHeader>
                      <TableHeader>Unit</TableHeader>
                      <TableHeader>Action</TableHeader>
                    </TableRow>
                  </thead>
                  <tbody>
                    {filteredPaginatedUsersSelect
                      .filter((item) => item.role === selectedRole) // Filter by selectedRole
                      .map((item, index) => (
                        <TableRow key={index}>
                          <TableCell style={{ minWidth: "60px" }}>
                            <input
                              type="checkbox"
                              checked={selectedUsers.includes(item.user_id)} // Check if user_id is in selectedUsers
                              onChange={(event) =>
                                handleSelectChange(
                                  index,
                                  "user",
                                  event.target.checked
                                )
                              }
                            />
                          </TableCell>
                          <TableCell style={{ minWidth: "60px" }}>
                            <ProfileImage
                              src={item.image || userImage}
                              alt={item.username}
                            />
                          </TableCell>
                          <TableCell>{item.username}</TableCell>
                          <TableCell>{item.role || "N/A"}</TableCell>
                          <TableCell>{item.user_id}</TableCell>
                          <TableCell>{item.group_name}</TableCell>
                          <TableCell>{item.unit_name || "N/A"}</TableCell>
                          <TableCell>
                            <AssignNowButton
                              onClick={() => {
                                handleAssignClick();
                                handleClose();
                              }}
                            >
                              Assign now
                            </AssignNowButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </tbody>
                </Table>
              </TableWrapper>
              <Pagination
                currentPage={currentPageUser}
                totalItems={
                  filteredPaginatedUsersSelect.filter(
                    (item) => item.role === selectedRole
                  ).length
                }
                itemsPerPage={itemsPerPageUser}
                onPageChange={(page) => setCurrentPageUser(page)}
              />
            </Container>
          </Modal.Body>
        </Modal>
      </>
    </Containers>
  );
};

// Define the delete function
const handleDelete = (id) => {
  console.log(`Delete user with ID: ${id}`);
};

export default Listofusers;

// Styled Components
const Containers = styled.div`
  padding: 20px;
  background-color: #f4f8fc;
  min-height: 100vh;
  font-family: Arial, sans-serif;

  
`;

const FilterSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const FilterLabel = styled.span`
  font-weight: bold;
  color: #555;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .no-data-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 87;  
    text-align: center;
    background-color: #f9f9f9; 
    color: #555; 
    margin-top: 25px;
    border: 1px solid blue;
  }

  .no-data-container h2 {
    font-size: 2rem;
    margin: 0.5rem 0;
    color: #333;  
  }

  .no-data-container p {
    font-size: 1rem;
    margin: 0.5rem 0;
    color: #777; 
  }

  .no-data-container .icon {
    font-size: 3rem;
    color: #bbb;  
    margin-bottom: 1rem;
  }

  .no-data-container .line {
    width: 80px;
    height: 2px;
    background-color: #ddd;  
    margin: 1rem 0;
  }
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const TableHeader = styled.th`
  padding: 12px;
  text-align: left;
  background-color: #e3f2fd;
  color: #333;
 
`;

const TableCell = styled.td`
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
 
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    background-color: #0056b3;
  }
`;

const Dropdown = styled.select`
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
`;

const DeleteIcon = styled.div`
  color: #ff4d4f;
  cursor: pointer;
  font-size: 20px;
  &:hover {
    color: #ff1a1a;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  padding: 8px 12px;
  margin: 0 5px;
  background-color: ${(props) => (props.active ? "#0056b3" : "#007bff")};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;
