import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import styled from "styled-components";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { NewUserRegisterAction } from "../../redux/users/action";
import useAppUtils from "../../utils/useAppUtils";

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .required("Username is required")
    .min(4, "Username must be at least 4 characters"),
  email: Yup.string().required("email is required").email("Invalid email format"),
  mobile_number: Yup.string()
  .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits")
  .required("Mobile number is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must contain at least one letter, one number, and one special character"
    ),
    confirm_password: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const NewUserRegister = () => {
  const { dispatch, navigate } = useAppUtils();
  const handleRegistration = (values, { setSubmitting, resetForm }) => {
    const { username,email,mobile_number, password } = values;
    dispatch(NewUserRegisterAction({ username,email,mobile_number, password }, (response) => {
      setSubmitting(false); 
      if (response?.status === 200  ) {
        toast.success(response.message);  
        navigate("/newlogin");
      } else {
        toast.error("Login failed. Please try again.");
      }
      console.log("Login response:", response);
    }));
    resetForm();  
  };
  return (
    <Root>
      <Formik
        initialValues={{ username: "", email: "", mobile_number: "", password: "", confirm_password: "" }}
        validationSchema={validationSchema}
        onSubmit={handleRegistration}
      >
        {({ isSubmitting }) => (
          <RegistrationContainer>
            <RegistrationForm>
              <Title>Register</Title>
              <Field
                type="text"
                name="username"
                placeholder="Username"
                as={Input}
              />
              <ErrorMessage component={ErrorText} name="username" />
              <Field
                type="email"
                name="email"
                placeholder="Email"
                as={Input}
              />
              <ErrorMessage component={ErrorText} name="email" />
              <Field
                type="text"
                name="mobile_number"
                placeholder="Mobile Number"
                as={Input}
              />
              <ErrorMessage component={ErrorText} name="mobile_number" />
              <Field
                type="password"
                name="password"
                placeholder="Password"
                as={Input}
              />
              <ErrorMessage component={ErrorText} name="password" />
              <Field
                type="text"
                name="confirm_password"
                placeholder="Confirm Password"
                as={Input}
              />
              <ErrorMessage component={ErrorText} name="confirm_password" />
              <ForgotPasswordContainer>
                Already have an account?
                <ForgotPasswordLink href="/newlogin">
                  Login
                </ForgotPasswordLink>
              </ForgotPasswordContainer>
              <RegisterButton type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Registering..." : "Register"}
              </RegisterButton>
            </RegistrationForm>
          </RegistrationContainer>
        )}
      </Formik>
    </Root>
  );
};
export default NewUserRegister;
const Root = styled.section`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
 
`;
export const ForgotPasswordContainer = styled.div`
  width: 100%;
  text-align: start;
  margin-bottom: 1rem;
  justify-content: space-between;
  display: flex;
`;
export const ForgotPasswordLink = styled.a`
  color: #007bff;
  text-decoration: none;
  font-size: 0.9rem;

  &:hover {
    text-decoration: underline;
    color: #0056b3;
  }
`;
const RegistrationContainer = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 2rem;
  background: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;
const RegistrationForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: #333;
`;
const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  margin: 0.5rem 0;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  &:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
  }
`;
const RegisterButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  margin-top: 1rem;
  background-color:#1814f3;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color:#1814f3;
  }
  &:active {
    background-color: #004494;
  }
`;
const ErrorText = styled.div`
  color: red;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
`;
