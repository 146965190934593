import React from "react";
import { toast } from "react-toastify";
import {
  userCheckAction,
  userDataAction,
  userLoginAction,
} from "../../redux/users/action";
import useAppUtils, { usePermission } from "../../utils/useAppUtils";
import styled from "styled-components";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  email: Yup.string().required("email is required").email(),
});

const UserLogin = () => {
  const { dispatch, navigate } = useAppUtils(); // Use custom hook
  const  permission= usePermission();
  const handleLogin = (values, { setSubmitting }) => {
    const { email } = values;
    dispatch(
      userLoginAction({ email }, (response) => {
        setSubmitting(false);
        if (response?.status === 200) {
          localStorage.setItem("token", response?.data?.data?.token);
            if (permission.length === 0) {
             toast.error("You don't have any permissions available to access this app.")
              localStorage.removeItem("token"); 
              setSubmitting(false);
              return; 
            }
            toast.success("Login successful!");
            dispatch(userDataAction(response.data.data));
            dispatch(userCheckAction(true));
            navigate("/"); 
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else if (response?.status === 400) {
            toast.error(response.data.data.message);
            navigate("/userlogin");
          }
        console.log("Login response:", response);
      })
    );
  };
  return (
    <Root>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={validationSchema}
        onSubmit={handleLogin}
      >
        {({ isSubmitting }) => (
          <LoginContainer>
            <LoginForm>
              <Title>User Login</Title>
              <Field type="text" name="email" placeholder="email" as={Input} />
              <ErrorMessage component={ErrorText} name="email" />
              <LoginButton type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Logging in..." : "Login"}
              </LoginButton>
            </LoginForm>
          </LoginContainer>
        )}
      </Formik>
    </Root>
  );
};
export default UserLogin;
const Root = styled.section`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
`;
const LoginContainer = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 2rem;
  background: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;
const LoginForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: #333;
`;
const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  margin: 0.5rem 0;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  &:focus {
    border-color: #4caf50;
    outline: none;
    box-shadow: 0 0 8px rgba(76, 175, 80, 0.2);
  }
`;
const LoginButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  margin-top: 1rem;
  background-color: #1814f3;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #1814f3;
  }
  &:active {
    background-color: #388e3c;
  }
`;
const ErrorText = styled.div`
  color: red;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
`;
