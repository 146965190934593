import React from "react";
import styled from "styled-components";
import GlobalTable from "../Global/GlobalTable";
import { BlackBorderButton } from "../Global/GlobalButtons";
import { useUserList } from "../../utils/useAppUtils";

const UsersList = () => {
  const users = useUserList();

  const columns = [
    { header: "Select", accessor: "select" },
    { header: "User Name", accessor: "userName" },
    { header: "Group Name", accessor: "groupName" },
    { header: "Unit Name", accessor: "unitName" },
    { header: "Role ", accessor: "role" },
    { header: "Request Sent ", accessor: "request" },
  ];

  

  return (
    <StyleUserList>
      <div className="dash_table">
        <GlobalTable
          columns={columns}
          data={users.map((user, index) => ({
            userName: user.username || "No Data",
            groupName: user.groupname || "No Data",
            unitName: user.unitname || "No Data",
            role: user.role || "No Data",
            request: (
              <BlackBorderButton onClick={() => alert(`Request Accepted`)}>
                Accepted
              </BlackBorderButton>
            ),
          }))}
        />
      </div>
    </StyleUserList>
  );
};

export default UsersList;
const StyleUserList = styled.section``;
  