import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  NewUserLoginAction,
  userCheckAction,
  userDataAction,
} from "../../redux/users/action";
import useAppUtils from "../../utils/useAppUtils";
import styled from "styled-components";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  ForgotPasswordContainer,
  ForgotPasswordLink,
} from "./RegistrationPage";
import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .required("Username is required")
    .min(3, "Username must be at least 3 characters"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .matches(
      /^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),
});

const NewUserLogin = () => {
  const { dispatch, navigate } = useAppUtils(); // Use custom hook
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = (values, { setSubmitting }) => {
    const { username, password } = values;

    dispatch(
      NewUserLoginAction({ username, password }, (response, resetForm) => {
        setSubmitting(false); 
        if (response?.status === 200) {
          const user = response?.data?.data;
          console.log("to set role", user);
          localStorage.setItem("token", user.token);
          localStorage.setItem("role", user.role);
          dispatch(userDataAction(user));
          dispatch(userCheckAction(true));

          if (user.role === "groupadmin" && user.is_verified === 1) {
            navigate("/dashboard");
          } else if (user.is_verified === 0) {
            navigate("/choose_portal");
          } else if (user.is_verified === 1) {
            navigate("/waiting-unitapproove");
          }
        } else {
          resetForm();
          toast.error("Login failed. Please try again.");
        }
      })
    );
  };

  return (
    <Root>
      <Formik
        initialValues={{ username: "", password: "" }}
        validationSchema={validationSchema}
        onSubmit={handleLogin}
      >
        {({ isSubmitting }) => (
          <LoginContainer>
            <LoginForm>
              <Title>New User Login</Title>
              <Field
                type="text"
                name="username"
                placeholder="Username"
                as={Input}
              />
              <ErrorMessage component={ErrorText} name="username" />
              <PasswordContainer>
                <Field
                  type={showPassword ? "text" : "password"}  
                  name="password"
                  placeholder="Password"
                  as={Input}
                />
                <ToggleButton
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <IoMdEyeOff /> : <IoMdEye />}
                </ToggleButton>
              </PasswordContainer>
              <ErrorMessage component={ErrorText} name="password" />
              <ForgotPasswordContainer>
                Don`t have an account?
                <ForgotPasswordLink href="/newregister">
                  Register
                </ForgotPasswordLink>
              </ForgotPasswordContainer>
              <ForgotPasswordContainer style={{ justifyContent: "right " }}>
                <ForgotPasswordLink href="/forgot-password">
                  Forgot Password?
                </ForgotPasswordLink>
              </ForgotPasswordContainer>

              <LoginButton type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Logging in..." : "Login"}
              </LoginButton>
            </LoginForm>
          </LoginContainer>
        )}
      </Formik>
    </Root>
  );
};
export default NewUserLogin;
const Root = styled.section`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
`;
const LoginContainer = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 2rem;
  background: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;
const LoginForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: #333;
`;
const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  margin: 0.5rem 0;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  &:focus {
    border-color: #4caf50;
    outline: none;
    box-shadow: 0 0 8px rgba(76, 175, 80, 0.2);
  }
`;
const LoginButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  margin-top: 1rem;
  background-color: #1814f3;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #1814f3;
  }
  &:active {
    background-color: #388e3c;
  }
`;
const ErrorText = styled.div`
  color: red;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
`;
const PasswordContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
`;
const ToggleButton = styled.button`
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 0.9rem;
  color: #00000073;
  &:hover {
    color: #000000;
  }
`;
