import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import avatar from "../../Assets/284950b1-c899-44d4-927f-a2859cac3dc8.jpeg";

const UserProfile = () => {
  const userDetails = useSelector((state) => state?.users?.user);

  return (
    <Container>
      <Avatar src={avatar} alt={userDetails.username} />
      <Name>{userDetails.username}</Name>
      <Bio>{userDetails.role}</Bio>
      <SocialMedia>A passionate developer and designer.</SocialMedia>
    </Container>
  );
};

export default UserProfile;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  border: 1px double lightgray;
  width: 60vw;
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Avatar = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
`;

const Name = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
`;

const Bio = styled.p`
  font-size: 16px;
  text-align: center;
  text-transform: capitalize;
`;

const SocialMedia = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  a {
    margin: 0 10px;
    font-size: 24px;
    color: #333;
  }
`;
