import React, { useState } from "react";
import { IoSearchSharp } from "react-icons/io5";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedGroupIds } from "../../redux/users/action";
import { useGroupList } from "../../utils/useAppUtils";
import GlobalTable from "../Global/GlobalTable";
import { Pagination } from "../Global/Table2";
import { BorderSelect } from "../Global/GlobalPlaceholder";

export const AllGroup = () => {
  const [selectedButton, setSelectedButton] = useState("Group");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Items per page
  const [searchTerm, setSearchTerm] = useState("");
  const [searchCriteria, setSearchCriteria] = useState("group_name"); // Default search field

  const groups = useGroupList(); // Fetch groups
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Define columns for the table
  const columns = [
    { header: "SL No", accessor: "group_id" },
    { header: "Create Date & Time", accessor: "dateTime" },
    { header: "Created By", accessor: "createdBy" },
    { header: "Group List", accessor: "groupList" },
    { header: "Unit List", accessor: "unitList" },
    { header: "Member List", accessor: "memberList" },
    { header: "Equipment List", accessor: "equipmentList" },
  ];

  // Format date to YYYY-MM-DD
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Navigate to group details
  const handleNavigateToUnit = (groupid) => {
    dispatch(setSelectedGroupIds(groupid));
    navigate(`/group_byid/${groupid}`);
  };

  // Filter groups based on search term and criteria
  const filteredGroups = groups.filter((group) => {
    const fieldToSearch = group[searchCriteria]?.toString().toLowerCase() || "";
    return fieldToSearch.includes(searchTerm.toLowerCase());
  });

  // Paginate filtered groups
  const paginatedGroups = filteredGroups.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <Root>
      {/* Search Bar */}
      <div className="search-bar-container">
        <div className="search-wrapper">
          <div className="icon_div">
            <IoSearchSharp />
          </div>
          <div className="icon_content">
            <input
              type="text"
              placeholder={`Search by ${
                searchCriteria === "group_name"
                  ? "Group Name & Creator"
                  : "Creator"
              }`}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
          </div>
        </div>

        <div className="search-criteria-selector">
          <BorderSelect
            value={searchCriteria}
            onChange={(e) => setSearchCriteria(e.target.value)}
            className="search-criteria-dropdown"
          >
            <option value="group_name">Group Name</option>
            <option value="role">Creator</option>
          </BorderSelect>
        </div>
      </div>

      {/* Table */}
      <TableDiv>
        <div className="dash_table">
          <GlobalTable
            columns={columns}
            data={paginatedGroups.map((group, index) => ({
              group_id: group.group_id,
              dateTime: group.create_date
                ? formatDate(group.create_date)
                : "No Data",
              createdBy: group.role || "No Role",
              groupList: (
                <span
                  onClick={() => handleNavigateToUnit(group.group_id)}
                  style={{ cursor: "pointer", color: "blue" }}
                >
                  {group.group_name || "No Data"}
                </span>
              ),
              unitList: group.unitId ? `Unit ${group.unitId}` : "No Data",
              memberList: group.user_count || "No Data",
              equipmentList: group.equipment_permission_count || "No Data",
            }))}
          />
        </div>

        {/* Pagination */}
        <Pagination
          currentPage={currentPage}
          totalItems={filteredGroups.length}
          itemsPerPage={itemsPerPage}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </TableDiv>
    </Root>
  );
};

const Root = styled.section`
  .search-bar-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    .search-wrapper {
      margin: 3px;
    border: 1px solid lightgray;
    background-color: #ffffffac;
    height: 55px;
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 0 20px;
    border-radius: 40px;
    .icon_div {
      width: 3%;
      svg {
        color: #718ebf;
        width: 35px;
        height: 35px;
      }
    }

    .icon_content {
      width: 97%;
      cursor: pointer;
      input[type="text"] {
        height: 50px;
        width: 100%;
        border: none;
        font-size: 20px;
        font-weight: 400;
        line-height: 24.2px;
        padding: 0 10px;
        border-radius: 40px;
        color: #8ba3cb;
        outline: none;
        background-color: transparent;
      }

      input[type="text"]::placeholder {
        color: #8ba3cb;
        background-color: transparent;
      }
    }
  }
  }
  .search-criteria-dropdown {
    background-color: #fff;
    height: 50px;
    color: #8ba3cb;
    border-radius: 20px;
  }
  #search-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    flex: 1;
  }

  #search-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
`;

const ButtonWrapper = styled.div`
  margin: 10px 0px 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
`;

export const ProductList = styled.div`
  padding: 20px 0px 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const ButtonDiv = styled.div`
  display: flex;
  gap: 20px;
`;

export const TableDiv = styled.section`
  display: flex;
  flex-direction: column;
  .dash_table {
    background-color: #ffff;
    border-radius: 25px;
    padding: 8px;
    overflow: auto;
  }
`;
