import styled from "styled-components";

export const Textlabel = styled.label`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  color: #232323;
`;

export const Heading = styled.h2`
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 26.63px;
  color: #333b69;
  margin: 0;
`;

export const TableHeader = styled.h5`
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  color: #718ebf;
`;

export const TableContent = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  color: #000000;
`;

export const FormTextBlack = styled.p`
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  color: #000000;
  margin: 0;
`;

export const FormTextRed = styled.p`
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  color: #ff0000;
`;

export const MainHeading = styled.h1`
  font-size: 30px;
  font-weight: 400;
  line-height: 77.45px;
  color: #718ebf;
  margin: 0;
  @media (max-width: 567px) {
    font-size: 20px;
  }
`;

export const ProfileCount = styled.p`
  font-size: 30px;
  font-weight: 600;
  line-height: 58.09px;
  color: #232323;
  margin: 0;
`;

export const UploadContent = styled.span`
  font-size: 20px;
  font-weight: 400;
  line-height: 38.73px;
  color: #4b4949;
`;

export const HistoryContent = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: #4b4949;
`;

export const HistorySubContent = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: #4b4949;
`;

export const BlueHeading = styled.span`
font-size:16px;
font-weight:500;
line-height:24.2px;
color:#2D60FF;
`;

export const GroupHead = styled.section`
font-size: 13px;
font-weight: 500;
line-height: 15.73px;
color:#232323;
`

export const GroupSubHead = styled.section`

font-size: 12px;
font-weight: 400;
line-height: 14.52px;
color:#718EBF;
`
 export const ErrorMsg = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
 `;
