import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CheckboxInput, CheckboxLabel } from "./GlobalPlaceholder";
import { BlueHeading } from "./GlobalText";
import { RiArrowDownWideLine } from "react-icons/ri";
import { BlueBackgroundButton } from "./GlobalButtons";

export default function GlobalUserForm() {
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isParentChecked, setIsParentChecked] = useState(false);
  const [isThirdPartyParentChecked, setIsThirdPartyParentChecked] =
    useState(false);
  const [isWareHouseManagement, setIsWareHouseManagement] = useState(false);
  const [isRequestEquipment, SetIsRequestEquipment] = useState(false);
  const [isEquipmentThirdParty, setIsEquipmentThirdParty] = useState(false);
  const [isGrantPermission, setIsGrantPermission] = useState(false);
  const [noLimitCheckboxTwo, setNoLimitCheckboxTwo] = useState(false);
  const [childCheckboxes, setChildCheckboxes] = useState({
    child1: false,
    child2: false,
    child3: false,
    child4: false,
    child5: false,
  });
  const [isAdminBoard, setIsAdminBoard] = useState(false);
  const [noLimitCheckbox, setNoLimitCheckbox] = useState(false);
  const [thirdPartyCheckboxes, setThirdPartyCheckboxes] = useState({
    child6: false,
    child7: false,
    child8: false,
    child9: false,
    child10: false,
    child11: false,
    child12: false,
  });
  const [requestEquipmentCheckboxes, setRequestEquipmentCheckboxes] = useState({
    child13: false,
    child14: false,
    child15: false,
    child16: false,
    child17: false,
  });
  const [EquipmentThirdPartyCheckboxes, setEquipmentThirdPartyCheckboxes] =
    useState({
      child18: false,
      child19: false,
      child20: false,
      child21: false,
      child22: false,
      child23: false,
      child24: false,
    });
  const [wareHouseManagement, setwareHouseManagement] = useState({
    child25: false,
    child26: false,
    child27: false,
    child28: false,
    child29: false,
    child30: false,
    child31: false,
    child32: false,
    child33: false,
    child34: false,
  });
  const [grantPermission, setgrantPermission] = useState({
    child35: false,
    child36: false,
  });
  const [adminBoard, setadminBoard] = useState({
    child37: false,
    child38: false,
    child39: false,
    child40: false,
    child41: false,
    child42: false,
    child43: false,
    child44: false,
    child45: false,
    child46: false,
    child47: false,
  });
  const handleSelectAllChange = (event) => {
    const checked = event.target.checked;
    setIsAllSelected(checked);
    setIsParentChecked(checked); 
    setChildCheckboxes({
      child1: checked,
      child2: checked,
      child3: checked,
      child4: checked,
      child5: checked,
    });
    setIsThirdPartyParentChecked(checked);
    setThirdPartyCheckboxes({
      child6: checked,
      child7: checked,
      child8: checked,
      child9: checked,
      child10: checked,
      child11: checked,
      child12: checked,
    });

    setNoLimitCheckbox(checked); 

    SetIsRequestEquipment(checked);
    setRequestEquipmentCheckboxes({
      child13: checked,
      child14: checked,
      child15: checked,
      child16: checked,
      child17: checked,
    });

    setIsEquipmentThirdParty(checked);
    setEquipmentThirdPartyCheckboxes({
      child18: checked,
      child19: checked,
      child20: checked,
      child21: checked,
      child22: checked,
      child23: checked,
      child24: checked,
    });

    setIsWareHouseManagement(checked);
    setwareHouseManagement({
      child25: checked,
      child26: checked,
      child27: checked,
      child28: checked,
      child29: checked,
      child30: checked,
      child31: checked,
      child32: checked,
      child33: checked,
      child34: checked,
    });

    setIsGrantPermission(checked);
    setgrantPermission({
      child35: checked,
      child36: checked,
    });

    setIsAdminBoard(checked);
    setadminBoard({
      child37: checked,
      child38: checked,
      child39: checked,
      child40: checked,
      child41: checked,
      child42: checked,
      child43: checked,
      child44: checked,
      child45: checked,
      child46: checked,
      child47: checked,
    });

    setNoLimitCheckboxTwo(checked);
  };

  const handleParentChange = (event) => {
    const checked = event.target.checked;
    setIsParentChecked(checked);
    setChildCheckboxes((prevState) => ({
      ...prevState,
      child1: checked,
      child2: checked,
      child3: checked,
      child4: checked,
      child5: checked,
    }));
    if (!checked) {
      setIsAllSelected(false);
    }
  };

  const handleThirdPartyParentChange = (event) => {
    const checked = event.target.checked;
    setIsThirdPartyParentChecked(checked);
    setThirdPartyCheckboxes({
      child6: checked,
      child7: checked,
      child8: checked,
      child9: checked,
      child10: checked,
      child11: checked,
      child12: checked,
    });
  };

  const handleNoLimitChange = (event) => {
    const checked = event.target.checked;
    setNoLimitCheckbox(checked);

    if (!checked) {
      setIsAllSelected(false);
    }
  };

  const handleRequestEquipmentParentChange = (event) => {
    const checked = event.target.checked;
    SetIsRequestEquipment(checked);
    setRequestEquipmentCheckboxes({
      child13: checked,
      child14: checked,
      child15: checked,
      child16: checked,
      child17: checked,
    });
    if (!checked) {
      setIsAllSelected(false);
    }
  };

  const handleThirdPartyEquipmentParentChange = (event) => {
    const checked = event.target.checked;
    setIsEquipmentThirdParty(checked);
    setEquipmentThirdPartyCheckboxes({
      child18: checked,
      child19: checked,
      child20: checked,
      child21: checked,
      child22: checked,
      child23: checked,
      child24: checked,
    });
    if (!checked) {
      setIsAllSelected(false);
    }
  };

  const handleWareHouseManagementParent = (event) => {
    const checked = event.target.checked;
    setIsWareHouseManagement(checked);
    setwareHouseManagement({
      child25: checked,
      child26: checked,
      child27: checked,
      child28: checked,
      child29: checked,
      child30: checked,
      child31: checked,
      child32: checked,
      child33: checked,
      child34: checked,
    });

    if (!checked) {
      setIsAllSelected(false);
    }
  };

  const handleGrantPermissionParent = (event) => {
    const checked = event.target.checked;
    setIsGrantPermission(checked);
    setgrantPermission({
      child35: checked,
      child36: checked,
    });
    if (!checked) {
      setIsAllSelected(false);
    }
  };

  const handleAdminBoardParent = (event) => {
    const checked = event.target.checked;
    setIsAdminBoard(checked);
    setadminBoard({
      child37: checked,
      child38: checked,
      child39: checked,
      child40: checked,
      child41: checked,
      child42: checked,
      child43: checked,
      child44: checked,
      child45: checked,
      child46: checked,
      child47: checked,
    });
    if (!checked) {
      setIsAllSelected(false);
    }
  };

  const handleChildChange = (event) => {
    const { id, checked } = event.target;
    setChildCheckboxes((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
    // Update "Select All Permission" based on child checkboxes
    if (!checked) {
      setIsAllSelected(false);
    }
  };

  const handleThirdPartyChildChange = (event) => {
    const { id, checked } = event.target;
    setThirdPartyCheckboxes((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
    if (!checked) {
      setIsAllSelected(false);
    }
  };

  const handleRequestEquipmentChildChange = (event) => {
    const { id, checked } = event.target;
    setRequestEquipmentCheckboxes((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
    if (!checked) {
      setIsAllSelected(false);
    }
  };

  const handleThirdPartyEquipmentChildChange = (event) => {
    const { id, checked } = event.target;
    setEquipmentThirdPartyCheckboxes((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
    if (!checked) {
      setIsAllSelected(false);
    }
  };

  const handleWareHouseManagementChild = (event) => {
    const { id, checked } = event.target;
    setwareHouseManagement((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
    if (!checked) {
      setIsAllSelected(false);
    }
  };

  const handleGrantPermissionChild = (event) => {
    const { id, checked } = event.target;
    setgrantPermission((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
    if (!checked) {
      setIsAllSelected(false);
    }
  };

  const handleAdminBoardChild = (event) => {
    const { id, checked } = event.target;
    setadminBoard((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
    if (!checked) {
      setIsAllSelected(false);
    }
  };

  const handleNoLimitChangeTwo = (event) => {
    const checked = event.target.checked;
    setNoLimitCheckboxTwo(checked);

    if (!checked) {
      setIsAllSelected(false);
    }
  };
  const countSelectedCheckboxesOne = () => {
    return Object.values(childCheckboxes).filter((checked) => checked).length;
  };

  const countSelectedCheckboxesTwo = () => {
    return Object.values(thirdPartyCheckboxes).filter((checked) => checked)
      .length;
  };

  const countSelectedCheckboxesThree = () => {
    return noLimitCheckbox ? 1 : 0;
  };

  const countSelectedCheckboxesFour = () => {
    return Object.values(requestEquipmentCheckboxes).filter(
      (checked) => checked
    ).length;
  };

  const countSelectedCheckboxesFive = () => {
    return Object.values(EquipmentThirdPartyCheckboxes).filter(
      (checked) => checked
    ).length;
  };

  const countSelectedCheckboxesSix = () => {
    //Second
    return noLimitCheckboxTwo ? 1 : 0;
  };

  const countSelectedCheckboxesSeven = () => {
    return Object.values(wareHouseManagement).filter((checked) => checked)
      .length;
  };

  const countSelectedCheckboxesEight = () => {
    return Object.values(grantPermission).filter((checked) => checked).length;
  };

  const countSelectedCheckboxesNine = () => {
    return Object.values(adminBoard).filter((checked) => checked).length;
  };

  // Effect to monitor changes to all checkboxes and update "Select All Permission"
  useEffect(() => {
    const areAllCheckboxesSelected = () => {
      return (
        isParentChecked &&
        Object.values(childCheckboxes).every((checked) => checked) &&
        isThirdPartyParentChecked &&
        Object.values(thirdPartyCheckboxes).every((checked) => checked) &&
        noLimitCheckbox &&
        isRequestEquipment && //parent and child checkbox
        Object.values(requestEquipmentCheckboxes).every((checked) => checked) &&
        isEquipmentThirdParty &&
        Object.values(EquipmentThirdPartyCheckboxes).every(
          (checked) => checked
        ) &&
        isWareHouseManagement &&
        Object.values(wareHouseManagement).every((checked) => checked) &&
        isGrantPermission && //parent and child checkbox
        Object.values(grantPermission).every((checked) => checked) &&
        isAdminBoard && //parent and child checkbox
        Object.values(adminBoard).every((checked) => checked) &&
        noLimitCheckboxTwo
      );
    };
    if (areAllCheckboxesSelected()) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
  }, [
    isParentChecked,
    childCheckboxes,
    isThirdPartyParentChecked,
    thirdPartyCheckboxes,
    noLimitCheckbox,
    isRequestEquipment,
    requestEquipmentCheckboxes,
    isEquipmentThirdParty,
    EquipmentThirdPartyCheckboxes,
    isWareHouseManagement,
    wareHouseManagement,
    isGrantPermission,
    grantPermission,
    isAdminBoard,
    adminBoard,
    noLimitCheckboxTwo,
  ]);

  return (
    <StyleUserForm>
      <div className="main_heading">
        <div className="check_label">
          <CheckboxInput
            type="checkbox"
            id="selectAll"
            checked={isAllSelected}
            onChange={handleSelectAllChange}
          />
          <CheckboxLabel htmlFor="selectAll">
            Select All Permission
          </CheckboxLabel>
        </div>

        <BlueHeading>Expand All</BlueHeading>
      </div>

      {/* Checkbox Row */}

      <div className="first_head_div left_marg">
        <div className="trans_equipment">
          <div className="check_label">
            <CheckboxInput
              type="checkbox"
              id="parentCheckbox"
              checked={isParentChecked}
              onChange={handleParentChange}
            />
            <CheckboxLabel htmlFor="parentCheckbox">
              Transfer of equipment
            </CheckboxLabel>
          </div>

          <div className="count_no">
            <CheckboxLabel htmlFor="vehicle1">
              {countSelectedCheckboxesOne()}/5
            </CheckboxLabel>
            <RiArrowDownWideLine />
          </div>
        </div>
        <div className="check_label left_marg">
          <CheckboxInput
            type="checkbox"
            id="child1"
            checked={childCheckboxes.child1}
            onChange={handleChildChange}
          />
          <CheckboxLabel htmlFor="child1">
            Transfer equipment (without the need for third-party approval)
          </CheckboxLabel>
        </div>
        <div className="check_label left_spac">
          <CheckboxInput
            type="checkbox"
            id="child2"
            checked={childCheckboxes.child2}
            onChange={handleChildChange}
          />
          <CheckboxLabel htmlFor="child2">Choice of place</CheckboxLabel>
        </div>
        <div className="check_label left_spac">
          <CheckboxInput
            type="checkbox"
            id="child3"
            checked={childCheckboxes.child3}
            onChange={handleChildChange}
          />
          <CheckboxLabel htmlFor="child3">
            Selection of equipment type:
          </CheckboxLabel>
        </div>

        <div className="check_label left_hig_spac">
          <CheckboxInput
            type="checkbox"
            id="child4"
            checked={childCheckboxes.child4}
            onChange={handleChildChange}
          />
          <CheckboxLabel htmlFor="child4">
            Choose between contains / does not contain
          </CheckboxLabel>
        </div>

        <div className="check_label left_hig_spac">
          <CheckboxInput
            type="checkbox"
            id="child5"
            checked={childCheckboxes.child5}
            onChange={handleChildChange}
          />
          <CheckboxLabel htmlFor="child5">What type of equipment</CheckboxLabel>
        </div>
      </div>

      {/* Checkbox Row */}

      <div className="first_head_div left_marg">
        <div className="trans_equipment">
          <div className="check_label">
            <CheckboxInput
              type="checkbox"
              id="thirdPartyParentCheckbox"
              checked={isThirdPartyParentChecked}
              onChange={handleThirdPartyParentChange}
            />
            <CheckboxLabel htmlFor="thirdPartyParentCheckbox">
              Transfer equipment (with the need for third-party approval)
            </CheckboxLabel>
          </div>

          <div className="count_no">
            <CheckboxLabel htmlFor="vehicle1">
              {countSelectedCheckboxesTwo()}/7
            </CheckboxLabel>
            <RiArrowDownWideLine />
          </div>
        </div>

        <div className="check_label left_marg">
          <CheckboxInput
            type="checkbox"
            id="child6"
            checked={thirdPartyCheckboxes.child6}
            onChange={handleThirdPartyChildChange}
          />
          <CheckboxLabel htmlFor="child6">Who is the third party</CheckboxLabel>
        </div>

        <div className="check_label left_spac">
          <CheckboxInput
            type="checkbox"
            id="child7"
            checked={thirdPartyCheckboxes.child7}
            onChange={handleThirdPartyChildChange}
          />
          <CheckboxLabel htmlFor="child7">
            Allow multiple users as third parties
          </CheckboxLabel>
        </div>

        <div className="check_label left_spac">
          <CheckboxInput
            type="checkbox"
            id="child8"
            checked={thirdPartyCheckboxes.child8}
            onChange={handleThirdPartyChildChange}
          />
          <CheckboxLabel htmlFor="child8">
            You can choose whether you need approval from all third parties /
            just one
          </CheckboxLabel>
        </div>

        <div className="check_label left_marg">
          <CheckboxInput
            type="checkbox"
            id="child9"
            checked={thirdPartyCheckboxes.child9}
            onChange={handleThirdPartyChildChange}
          />
          <CheckboxLabel htmlFor="child9">To whom can transfer</CheckboxLabel>
        </div>

        <div className="check_label left_marg">
          <CheckboxInput
            type="checkbox"
            id="child10"
            checked={thirdPartyCheckboxes.child10}
            onChange={handleThirdPartyChildChange}
          />
          <CheckboxLabel htmlFor="child10">
            Selection of equipment type
          </CheckboxLabel>
        </div>

        <div className="check_label left_spac">
          <CheckboxInput
            type="checkbox"
            id="child11"
            checked={thirdPartyCheckboxes.child11}
            onChange={handleThirdPartyChildChange}
          />
          <CheckboxLabel htmlFor="child11" style={{ color: "#FF0000" }}>
            Choose between contains / does not contain
          </CheckboxLabel>
        </div>

        <div className="check_label left_spac">
          <CheckboxInput
            type="checkbox"
            id="child12"
            checked={thirdPartyCheckboxes.child12}
            onChange={handleThirdPartyChildChange}
          />
          <CheckboxLabel htmlFor="child12">
            What type of equipment
          </CheckboxLabel>
        </div>
      </div>

      {/* Checkbox Row  count Pending*/}

      <div className="first_head_div left_marg">
        <div className="trans_equipment">
          <div className="check_label">
            <CheckboxInput
              type="checkbox"
              id="noLimitCheckbox"
              checked={noLimitCheckbox}
              onChange={handleNoLimitChange}
            />

            <CheckboxLabel htmlFor="noLimitCheckbox">
              Transfer equipment without limitation and without needing
              permission
            </CheckboxLabel>
          </div>

          <div className="count_no">
            <CheckboxLabel htmlFor="vehicle1">
              {countSelectedCheckboxesThree()}/1
            </CheckboxLabel>
            <RiArrowDownWideLine />
          </div>
        </div>
      </div>

      {/* Checkbox Row */}
      <div className="first_head_div left_marg">
        <div className="trans_equipment">
          <div className="check_label">
            <CheckboxInput
              type="checkbox"
              id="requestEquipmentParentCheckbox"
              checked={isRequestEquipment}
              onChange={handleRequestEquipmentParentChange}
            />
            <CheckboxLabel htmlFor="requestEquipmentParentCheckbox">
              Request equipment
            </CheckboxLabel>
          </div>

          <div className="count_no">
            <CheckboxLabel htmlFor="vehicle1">
              {countSelectedCheckboxesFour()}/5
            </CheckboxLabel>
            <RiArrowDownWideLine />
          </div>
        </div>

        <div className="check_label left_marg">
          <CheckboxInput
            type="checkbox"
            id="child13"
            checked={requestEquipmentCheckboxes.child13}
            onChange={handleRequestEquipmentChildChange}
          />
          <CheckboxLabel htmlFor="child13">
            Request equipment (without the need for third-party approval)
          </CheckboxLabel>
        </div>

        <div className="check_label left_spac">
          <CheckboxInput
            type="checkbox"
            id="child14"
            checked={requestEquipmentCheckboxes.child14}
            onChange={handleRequestEquipmentChildChange}
          />
          <CheckboxLabel htmlFor="child14">
            Choice of place: within the unit / within the selected group (you
            can check several boxes)
          </CheckboxLabel>
        </div>

        <div className="check_label left_marg">
          <CheckboxInput
            type="checkbox"
            id="child15"
            checked={requestEquipmentCheckboxes.child15}
            onChange={handleRequestEquipmentChildChange}
          />
          <CheckboxLabel htmlFor="child15">
            Selection of equipment type:
          </CheckboxLabel>
        </div>

        <div className="check_label left_spac">
          <CheckboxInput
            type="checkbox"
            id="child16"
            checked={requestEquipmentCheckboxes.child16}
            onChange={handleRequestEquipmentChildChange}
          />
          <CheckboxLabel htmlFor="child16" style={{ color: "#FF0000" }}>
            Choose between contains / does not contain
          </CheckboxLabel>
        </div>

        <div className="check_label left_spac">
          <CheckboxInput
            type="checkbox"
            id="child17"
            checked={requestEquipmentCheckboxes.child17}
            onChange={handleRequestEquipmentChildChange}
          />
          <CheckboxLabel htmlFor="child17">
            What type of equipment
          </CheckboxLabel>
        </div>
      </div>

      {/* Checkbox Row */}

      <div className="first_head_div left_marg">
        <div className="trans_equipment">
          <div className="check_label">
            <CheckboxInput
              type="checkbox"
              id="requestThirdPartyParentCheckbox"
              checked={isEquipmentThirdParty}
              onChange={handleThirdPartyEquipmentParentChange}
            />
            <CheckboxLabel htmlFor="requestThirdPartyParentCheckbox">
              Request equipment that requires third-party approval:
            </CheckboxLabel>
          </div>

          <div className="count_no">
            <CheckboxLabel htmlFor="vehicle1">
              {countSelectedCheckboxesFive()}/7
            </CheckboxLabel>
            <RiArrowDownWideLine />
          </div>
        </div>

        <div className="check_label left_marg">
          <CheckboxInput
            type="checkbox"
            id="child18"
            checked={EquipmentThirdPartyCheckboxes.child18}
            onChange={handleThirdPartyEquipmentChildChange}
          />
          <CheckboxLabel htmlFor="child18">
            Who is the third party{" "}
            <span style={{ color: "#FF0000" }}>(user search opens)</span>
          </CheckboxLabel>
        </div>

        <div className="check_label left_spac">
          <CheckboxInput
            type="checkbox"
            id="child19"
            checked={EquipmentThirdPartyCheckboxes.child19}
            onChange={handleThirdPartyEquipmentChildChange}
          />
          <CheckboxLabel htmlFor="child19">
            Allow multiple users as third parties
          </CheckboxLabel>
        </div>

        <div className="check_label left_spac">
          <CheckboxInput
            type="checkbox"
            id="child20"
            checked={EquipmentThirdPartyCheckboxes.child20}
            onChange={handleThirdPartyEquipmentChildChange}
          />
          <CheckboxLabel htmlFor="child20">
            You can choose whether you need approval from all third parties /
            just one
          </CheckboxLabel>
        </div>

        <div className="check_label left_marg">
          <CheckboxInput
            type="checkbox"
            id="child21"
            checked={EquipmentThirdPartyCheckboxes.child21}
            onChange={handleThirdPartyEquipmentChildChange}
          />
          <CheckboxLabel htmlFor="child21">Who can ask</CheckboxLabel>
        </div>

        <div className="check_label left_marg">
          <CheckboxInput
            type="checkbox"
            id="child22"
            checked={EquipmentThirdPartyCheckboxes.child22}
            onChange={handleThirdPartyEquipmentChildChange}
          />
          <CheckboxLabel htmlFor="child22">
            Selection of equipment type
          </CheckboxLabel>
        </div>

        <div className="check_label left_spac">
          <CheckboxInput
            type="checkbox"
            id="child23"
            checked={EquipmentThirdPartyCheckboxes.child23}
            onChange={handleThirdPartyEquipmentChildChange}
          />
          <CheckboxLabel htmlFor="child23">
            Choose between contains / does not contain
          </CheckboxLabel>
        </div>

        <div className="check_label left_spac">
          <CheckboxInput
            type="checkbox"
            id="child24"
            checked={EquipmentThirdPartyCheckboxes.child24}
            onChange={handleThirdPartyEquipmentChildChange}
          />
          <CheckboxLabel htmlFor="child24">
            What type of equipment
          </CheckboxLabel>
        </div>
      </div>

      {/* Checkbox Row  Pending six count*/}

      <div className="first_head_div left_marg">
        <div className="trans_equipment">
          <div className="check_label">
            <CheckboxInput
              type="checkbox"
              id="noLimitCheckboxTwo"
              checked={noLimitCheckboxTwo}
              onChange={handleNoLimitChangeTwo}
            />
            <CheckboxLabel htmlFor="noLimitCheckboxTwo">
              Take equipment without limit and without needing permission from
              the equipment recipient
            </CheckboxLabel>
          </div>

          <div className="count_no">
            <CheckboxLabel htmlFor="vehicle1">
              {" "}
              {countSelectedCheckboxesSix()} /1{" "}
            </CheckboxLabel>
            <RiArrowDownWideLine />
          </div>
        </div>
      </div>

      {/* Checkbox Row */}

      <div className="first_head_div left_marg">
        <div className="trans_equipment">
          <div className="check_label">
            <CheckboxInput
              type="checkbox"
              id="warehouseManagement"
              checked={isWareHouseManagement}
              onChange={handleWareHouseManagementParent}
            />
            <CheckboxLabel htmlFor="warehouseManagement">
              Warehouse Management
            </CheckboxLabel>
          </div>

          <div className="count_no">
            <CheckboxLabel htmlFor="vehicle1">
              {countSelectedCheckboxesSeven()}/10
            </CheckboxLabel>
            <RiArrowDownWideLine />
          </div>
        </div>

        <div className="check_label left_marg">
          <CheckboxInput
            type="checkbox"
            id="child25"
            checked={wareHouseManagement.child25}
            onChange={handleWareHouseManagementChild}
          />
          <CheckboxLabel htmlFor="child25">Change product name</CheckboxLabel>
        </div>

        <div className="check_label left_marg">
          <CheckboxInput
            type="checkbox"
            id="child26"
            checked={wareHouseManagement.child26}
            onChange={handleWareHouseManagementChild}
          />
          <CheckboxLabel htmlFor="child26">
            Change category to product
          </CheckboxLabel>
        </div>

        <div className="check_label left_marg">
          <CheckboxInput
            type="checkbox"
            id="child27"
            checked={wareHouseManagement.child27}
            onChange={handleWareHouseManagementChild}
          />

          <CheckboxLabel htmlFor="child27">
            Add equipment to the warehouse
          </CheckboxLabel>
        </div>

        <div className="check_label left_marg">
          <CheckboxInput
            type="checkbox"
            id="child28"
            checked={wareHouseManagement.child28}
            onChange={handleWareHouseManagementChild}
          />
          <CheckboxLabel htmlFor="child28">
            Transfer equipment from the warehouse to the user
          </CheckboxLabel>
        </div>

        <div className="check_label left_marg">
          <CheckboxInput
            type="checkbox"
            id="child29"
            checked={wareHouseManagement.child29}
            onChange={handleWareHouseManagementChild}
          />

          <CheckboxLabel htmlFor="child29">
            Transfer equipment from warehouse to sub warehouse (in unit)
          </CheckboxLabel>
        </div>

        <div className="check_label left_marg">
          <CheckboxInput
            type="checkbox"
            id="child30"
            checked={wareHouseManagement.child30}
            onChange={handleWareHouseManagementChild}
          />
          <CheckboxLabel htmlFor="child30">
            Receive equipment from user to warehouse
          </CheckboxLabel>
        </div>

        <div className="check_label left_spac">
          <CheckboxInput
            type="checkbox"
            id="child31"
            checked={wareHouseManagement.child31}
            onChange={handleWareHouseManagementChild}
          />
          <CheckboxLabel htmlFor="child31">Delete a product</CheckboxLabel>
        </div>

        <div className="check_label left_hig_spac">
          <CheckboxInput
            type="checkbox"
            id="child32"
            checked={wareHouseManagement.child32}
            onChange={handleWareHouseManagementChild}
          />
          <CheckboxLabel htmlFor="child32">With transfer history</CheckboxLabel>
        </div>

        <div className="check_label left_hig_spac">
          <CheckboxInput
            type="checkbox"
            id="child33"
            checked={wareHouseManagement.child33}
            onChange={handleWareHouseManagementChild}
          />
          <CheckboxLabel htmlFor="child33">
            without transfer history
          </CheckboxLabel>
        </div>

        <div className="check_label left_hig_spac">
          <CheckboxInput
            type="checkbox"
            id="child34"
            checked={wareHouseManagement.child34}
            onChange={handleWareHouseManagementChild}
          />
          <CheckboxLabel htmlFor="vehicle1" style={{ color: "#FF0000" }}>
            Give specific permissions
          </CheckboxLabel>
        </div>
      </div>

      {/* Checkbox Row */}

      <div className="first_head_div left_marg">
        <div className="trans_equipment">
          <div className="check_label">
            <CheckboxInput
              type="checkbox"
              id="grantPermission"
              checked={isGrantPermission}
              onChange={handleGrantPermissionParent}
            />
            <CheckboxLabel htmlFor="grantPermission">
              To grant permissions
            </CheckboxLabel>
          </div>

          <div className="count_no">
            <CheckboxLabel htmlFor="vehicle1">
              {countSelectedCheckboxesEight()}/2
            </CheckboxLabel>
            <RiArrowDownWideLine />
          </div>
        </div>

        <div className="check_label left_marg">
          <CheckboxInput
            type="checkbox"
            id="child35"
            checked={grantPermission.child35}
            onChange={handleGrantPermissionChild}
          />
          <CheckboxLabel htmlFor="child35" style={{ color: "#FF0000" }}>
            Give permissions that he has to other users
          </CheckboxLabel>
        </div>

        <div className="check_label left_marg">
          <CheckboxInput
            type="checkbox"
            id="child36"
            checked={grantPermission.child36}
            onChange={handleGrantPermissionChild}
          />
          <CheckboxLabel htmlFor="child36" style={{ color: "#FF0000" }}>
            Give all permissions
          </CheckboxLabel>
        </div>
      </div>

      {/* Checkbox Row */}

      <div className="first_head_div left_marg">
        <div className="trans_equipment">
          <div className="check_label">
            <CheckboxInput
              type="checkbox"
              id="adminBoard"
              checked={isAdminBoard}
              onChange={handleAdminBoardParent}
            />
            <CheckboxLabel htmlFor="adminBoard">Admin board</CheckboxLabel>
          </div>
          <div className="count_no">
            <CheckboxLabel htmlFor="vehicle1">
              {countSelectedCheckboxesNine()}/11
            </CheckboxLabel>
            <RiArrowDownWideLine />
          </div>
        </div>

        <div className="check_label left_marg">
          <CheckboxInput
            type="checkbox"
            id="child37"
            checked={adminBoard.child37}
            onChange={handleAdminBoardChild}
          />

          <CheckboxLabel htmlFor="child37">Add groups</CheckboxLabel>
        </div>

        <div className="check_label left_spac">
          <CheckboxInput
            type="checkbox"
            id="child38"
            checked={adminBoard.child38}
            onChange={handleAdminBoardChild}
          />
          <CheckboxLabel htmlFor="child38">
            Creating new groups in the unit
          </CheckboxLabel>
        </div>

        <div className="check_label left_spac">
          <CheckboxInput
            type="checkbox"
            id="child39"
            checked={adminBoard.child39}
            onChange={handleAdminBoardChild}
          />

          <CheckboxLabel htmlFor="child39" style={{ color: "#FF0000" }}>
            Request to annex existing groups from other units
          </CheckboxLabel>
        </div>

        <div className="check_label left_marg">
          <CheckboxInput
            type="checkbox"
            id="child40"
            checked={adminBoard.child40}
            onChange={handleAdminBoardChild}
          />
          <CheckboxLabel htmlFor="child40">Move groups</CheckboxLabel>
        </div>

        <div className="check_label left_spac">
          <CheckboxInput
            type="checkbox"
            id="child41"
            checked={adminBoard.child41}
            onChange={handleAdminBoardChild}
          />

          <CheckboxLabel htmlFor="child41">
            Move groups within the unit
          </CheckboxLabel>
        </div>

        <div className="check_label left_hig_spac">
          <CheckboxInput
            type="checkbox"
            id="child42"
            checked={adminBoard.child42}
            onChange={handleAdminBoardChild}
          />
          <CheckboxLabel htmlFor="child42">
            Access to a unit profile
          </CheckboxLabel>
        </div>

        <div className="check_label left_hig_spac ">
          <CheckboxInput
            type="checkbox"
            id="child43"
            checked={adminBoard.child43}
            onChange={handleAdminBoardChild}
          />

          <CheckboxLabel htmlFor="child43">Unit profile control</CheckboxLabel>
        </div>

        <div className="check_label left_spac">
          <CheckboxInput
            type="checkbox"
            id="child44"
            checked={adminBoard.child44}
            onChange={handleAdminBoardChild}
          />
          <CheckboxLabel htmlFor="child44">
            Send a request to another unit to transfer a group to it
          </CheckboxLabel>
        </div>

        <div className="check_label left_marg">
          <CheckboxInput
            type="checkbox"
            id="child45"
            checked={adminBoard.child45}
            onChange={handleAdminBoardChild}
          />

          <CheckboxLabel htmlFor="child45">
            Invite new users to the unit
          </CheckboxLabel>
        </div>

        <div className="check_label left_marg">
          <CheckboxInput
            type="checkbox"
            id="child46"
            checked={adminBoard.child46}
            onChange={handleAdminBoardChild}
          />

          <CheckboxLabel htmlFor="child46">
            Approve requests from users to join the unit
          </CheckboxLabel>
        </div>

        <div className="check_label left_marg">
          <CheckboxInput
            type="checkbox"
            id="child47"
            checked={adminBoard.child47}
            onChange={handleAdminBoardChild}
          />
          <CheckboxLabel htmlFor="child47">Open a sub-warehouse</CheckboxLabel>
        </div>
      </div>

      <div className="save_btn">
        <BlueBackgroundButton>Save</BlueBackgroundButton>
      </div>
    </StyleUserForm>
  );
}
const StyleUserForm = styled.section`
  .main_heading {
    display: flex;
    justify-content: space-between;
  }
  .left_marg {
    margin-left: 20px;
    margin-top: 5px;
  }

  .left_spac {
    margin-left: 40px;
  }

  .left_hig_spac {
    margin-left: 50px;
  }

  .check_label {
    display: flex;
    gap: 10px;
  }
  .first_head_div {
    margin-top: 30px;
    .trans_equipment {
      display: flex;
      justify-content: space-between;
      .count_no {
        display: flex;
        align-items: center;
      }
    }
  }

  .save_btn {
    display: flex;
    justify-content: flex-end;
  }
`;
