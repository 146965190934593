import React from "react";
import styled from "styled-components";
import { ThreeDots } from "react-loader-spinner"; 

const NoPermission = () => {
  return (
    <ContainerPer>
      <h2>Please wait...</h2>
      <Three>  <ThreeDots color="#00BFFF" height={80} width={80} /></Three>
      <p>Your permission request is being processed.</p>
      <p>Please wait until your permissions are assigned to you by the admin.</p>
    
    </ContainerPer>
  );
};
export default NoPermission;
export const ContainerPer = styled.div`
  padding: 20px;
  background-color: #f0f0f0;
  background-image: url("your-background-image.jpg");
  background-size: cover;
  background-position: center;
  text-align: center;
  h2 {
    color: #333;
    font-family: "Roboto", sans-serif;
  }
`;
export const Three = styled.p`
display: flex;
justify-content: center;
`