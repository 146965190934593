import {
  addUser,
  adminLogin,
  adminRegister,
  categoryList,
  createCategory,
  createGroup,
  createProduct,
  createUnit,
  createWarehouse,
  groupList,
  HIDE_LOADER,
  productPut,
  productView,
  SHOW_LOADER,
  unitList,
  userList,
  warehouseList,
  productList,
  warehouseLogin,
  userPermissionView,
  userLogin,
  userProductFetch,
  userSelectedList,
  createRoleUser,
  moveGrouptoUnit,
  deleteUnit,
  unitUserListGet,
  assignProduct,
  UserEquipmentListGetS,
  roleView,
  assignProductById,
  requestEquipmentFetch,
  reconcileProduct,
  ByGroupUserListGet,
  GroupRequestSent,
  updateGroupStatus,
  assignUserToGroup,
  MemberRequestSent,
  moveUserToGroup,
  assignUserToUnit,
  moveUserToUnit,
  updateMemberStatus,
  AddUserListGetForUNit,
  AddUserSecondListGetForUNit,
  NewUserRegister,
  newuserLogin,
  newuserloginTimeSendRequestForUnit,
  UserWaitingAprrovalFromUnit,
  RequestToJoinUnitForNewUserPost,
  MoveUserToOtherUnitListPost,
  SET_NEW_USER_VERIFY,
} from "../type";

export const SET_SELECTED_PRODUCT_ID = "SET_SELECTED_PRODUCT_ID";
export const setNewUserVerify = (newUserVerify) => ({
  type: SET_NEW_USER_VERIFY,
  payload: newUserVerify,
});
export const setSelectedProductId = (product_id) => ({
  type: SET_SELECTED_PRODUCT_ID,
  payload: product_id,
});
export const showLoader = () => ({ type: SHOW_LOADER });
export const hideLoader = () => ({ type: HIDE_LOADER });
export const setUserDetails = (data) => ({
  type: "USER_DETAILS",
  payload: data,
});
export const loaderAction = (data) => ({
  type: "LOADING_DATA",
  payload: data,
});
export const userDataAction = (data) => {
  return {
    type: "USER_DATA",
    payload: data,
  };
};
export const userCheckAction = (data) => ({
  type: "USER_CHECK",
  payload: data,
});
export const appDetailsAction = (data) => ({
  type: "APP_DETAILS",
  payload: data,
});
export const warehouseLoginAction = (data, callback) => ({
  type: warehouseLogin.WAREHOUSE_LOGIN,
  data,
  callback,
});
export const adminLoginAction = (data, callback) => ({
  type: adminLogin.ADMIN_LOGIN,
  data,
  callback,
});
export const userLoginAction = (data, callback) => ({
  type: userLogin.USER_LOGIN,
  data,
  callback,
});
export const adminRegisterAction = (data, callback) => ({
  type: adminRegister.ADMIN_REGISTER,
  data,
  callback,
});
export const addUserAction = (data, callback) => ({
  type: addUser.ADD_USER,
  data,
  callback,
});
export const createGroupAction = (data, callback) => ({
  type: createGroup.CREATE_GROUP,
  data,
  callback,
});
export const createUnitAction = (data, callback) => ({
  type: createUnit.CREATE_Unit,
  data,
  callback,
});
export const assignProductAction = (data, callback) => ({
  type: assignProduct.ASSIGN_PRODUCT,
  data,
  callback,
});
export const createWarehouseAction = (data, callback) => ({
  type: createWarehouse.CREATE_WAREHOUSE,
  data,
  callback,
});
export const CreateCategoryAction = (data, callback) => ({
  type: createCategory.CREATE_CATEGORY,
  data,
  callback,
});
export const ReconcileProductAction = (data, callback) => ({
  type: reconcileProduct.RECONCILE_PRODUCT,
  data,
  callback,
});
export const CreateProductAction = (data, callback) => ({
  type: createProduct.CREATE_PRODUCT,
  data,
  callback,
});
export const getUnitAction = (data, callback) => ({
  type: unitList.UNIT_LIST,
  data,
  callback,
});


export const getProductAction = (data, callback) => ({
  type: productList.PRODUCT_LIST,
  data,
  callback,
});

export const getGroupAction = (data, callback) => ({
  type: groupList.GROUP_LIST,
  data,
  callback,
});

export const getUserAction = (data, callback) => ({
  type: userList.USER_LIST,
  data,
  callback,
});
export const getUserSelectedAction = (data, callback) => ({
  type: userSelectedList.USER_SELECTED_LIST,
  data,
  callback,
});
export const getCategoryAction = (data, callback) => ({
  type: categoryList.CATEGORY_LIST,
  data,
  callback,
});
export const getWarehouseAction = (data, callback) => ({
  type: warehouseList.WAREHOUSE_LIST,
  data,
  callback,
});
export const getRoleAction = (data, callback) => ({
  type: roleView.ROLE_VIEW,
  data,
  callback,
});
export const getProductViewAction = (data, callback, product_id) => {
  console.log(product_id, "action id"); // Log the ID for debugging

  return {
    type: productView.PRODUCT_VIEW,
    product_id,
    data,
    callback,
  };
};
export const getUserPermissionViewAction = (data, callback) => {
  return {
    type: userPermissionView.USER_PERMISSION_VIEW,
    data,
    callback,
  };
};
export const getUserProductFetchAction = (data, callback) => ({
  type: userProductFetch.USER_PRODUCT_FETCH,
  data,
  callback,
});
export const putProductAction = (data, callback) => ({
  type: productPut.PRODUCT_PUT,
  data,
  callback,
});
export const assignProductByIdAction = (data, callback) => ({
  type: assignProductById.ASSIGN_PRODUCT_BY_ID,
  data,
  callback,
});
export const requestEquipmentFetchAction = (data, callback) => ({
  type: requestEquipmentFetch.REQUEST_EQUIPMENT_FETCH,
  data,
  callback,
});

export const createRoleUserAction = (data, callback) => ({
  type: createRoleUser.CREATE_ROLE_USER,
  data,
  callback,
});

export const createMoveGrouptoUnitAction = (data, callback) => ({
  type: moveGrouptoUnit.MOVE_GROUPTO_UNIT,
  data,
  callback,
});
export const DeleteUnitAction = (data, callback) => ({
  type: deleteUnit.DELETE_Unit,
  data,
  callback,
});

export const UnitUserListGet = (data, callback) => ({
  type: unitUserListGet.UNIT_USER_LISTGET,
  data,
  callback,
});

// actions.js
export const SET_SELECTED_UNIT_ID = "SET_SELECTED_UNIT_ID";

export const setSelectedUnitId = (unitId) => ({
  type: SET_SELECTED_UNIT_ID,
  payload: unitId,
});

export const SET_SELECTED_USER_ID = "SET_SELECTED_USER_ID";

export const setSelectedUserId = (id) => ({
  type: SET_SELECTED_USER_ID,
  payload: id,
});

export const EquipmentUserListGet = (data, callback) => ({
  type: UserEquipmentListGetS.USER_EQUIPMENT_LISTGET,
  data,
  callback,
});
export const SET_SELECTED_GROUPS_IDS = "SET_SELECTED_GROUPS_IDS";
export const setSelectedGroupIds = (groupid) => ({
  type: SET_SELECTED_GROUPS_IDS,
  payload: groupid,
});
export const GroupUserListGet = (data, callback) => ({
  type: ByGroupUserListGet.BY_GROUP_USER_LISTGET,
  data,
  callback,
});
export const assignUserToGroupAction = (data, callback) => ({
  type: assignUserToGroup.ASSIGN_USER_TO_GROUP,
  data,
  callback,
});
export const assignUserToUnitAction = (data, callback) => ({
  type: assignUserToUnit.ASSIGN_USER_TO_UNIT,
  data,
  callback,
});
export const moveUserToGroupAction = (data, callback) => ({
  type: moveUserToGroup.MOVE_USER_TO_GROUP,
  data,
  callback,
});
export const moveUserToUnitAction = (data, callback) => ({
  type: moveUserToUnit.MOVE_USER_TO_UNIT,
  data,
  callback,
});
export const groupRequestSentAction = (data, callback) => ({
  type: GroupRequestSent.GROUP_REQUEST_SENT,
  data,
  callback,
});

export const UpdateGroupStatusAction = (data, callback) => ({
  type: updateGroupStatus.UPDATE_GROUP_STATUS,
  data,
  callback,
});
export const UpdateMemberStatusAction = (data, callback) => ({
  type: updateMemberStatus.UPDATE_MEMBER_STATUS,
  data,
  callback,
});
export const MemberRequestSentAction = (data, callback) => ({
  type: MemberRequestSent.MEMBER_REQUEST_SENT,
  data,
  callback,
});
export const NewAddUserListAction = (data, callback) => ({
  type: AddUserListGetForUNit.ADD_NEW_USERLIST_GET_UNIT,
  data,
  callback,
});
export const SecondNewAddUserListAction = (data, callback) => ({
  type: AddUserSecondListGetForUNit.ADD_NEW_SECOND_USERLIST_GET_UNIT,
  data,
  callback,
});
export const NewUserRegisterAction = (data, callback) => ({
  type: NewUserRegister.NEW_USER_REGISTER,
  data,
  callback,
});
export const NewUserLoginAction = (data, callback) => ({
  type: newuserLogin.NEW_USER_LOGIN,
  data,
  callback,
});
export const NewUserLoginUnitSentRequestAction = (data, callback) => ({
  type: newuserloginTimeSendRequestForUnit.NEWUSER_SENTREQUEST_FORUNIT,
  data,
  callback,
});

export const WaitinUserUnitFetchAction = (data, callback) => ({
  type: UserWaitingAprrovalFromUnit.USER_WAITING_UNIT_LIST,
  data,
  callback,
});
export const RequestToJoinUnitForNewUserPostAction = (data, callback) => ({
  type: RequestToJoinUnitForNewUserPost.REQUEST_TO_JION_UNIT_FOR_NEW_USER_POST,
  data,
  callback,
});
export const MoveUserToOtherUnitListPostAction = (data, callback) => ({
  type: MoveUserToOtherUnitListPost.MOVE_USER_TO_OTHER_UNIT_LIST_POST,
  data,
  callback,
});
