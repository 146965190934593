import React from "react";
import {
  ProfileImage,
  TableCell,
  TableDiv,
  TableRow,
} from "../warehouse/Product";
import { BlueBorderButton } from "../Global/GlobalButtons";
import { RootTable } from "../Global/GlobalTable";
import userImage from "../../Assets/profile.png";
import { useMemberRequestSent, useRequestEquipment } from "../../utils/useAppUtils";
import styled from "styled-components";

const RequestEquipment = () => {
  const requestEquipment = useRequestEquipment();
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };
  return (
    <Root>
  <TableDiv>
    <div className="dash_table">
      <RootTable>
        <table>
          <thead>
            <tr>
              <th>SL No</th>
              <th>Date & Time</th>
              <th>Image</th>
              <th>Name</th>
              <th>Product ID</th>
              <th>Category</th>
              <th>Profile</th>
              <th>Requested By / Unit</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {requestEquipment.length > 0 ? (
              requestEquipment.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <input
                      type="checkbox"
                      // Add your selection logic here
                    />
                  </TableCell>
                  <TableCell>{formatDate(item.create_date || "N/A")}</TableCell>
                  <TableCell>
                    <ProfileImage
                      src={item.image || userImage}
                      alt={item.username}
                    />
                  </TableCell>
                  <TableCell>{item.username}</TableCell>
                  <TableCell>{item.productId || "N/A"}</TableCell>
                  <TableCell>{item.categoryname || "N/A"}</TableCell>
                  <TableCell>{item.username}</TableCell>
                  <TableCell>{item.requestbyRole || "N/A"}</TableCell>
                  <TableCell>
                    <BlueBorderButton variant="primary">
                      {item.status || "N/A"}
                    </BlueBorderButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <tr>
                <td colSpan="9" style={{ textAlign: "center", padding: "20px" }}>
                  No Data Available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </RootTable>
    </div>
  </TableDiv>
</Root>

  );
};

export default RequestEquipment;

const Root = styled.section`

`
;