export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const LOGOUT_USER = "LOGOUT_USER";
export const adminLogin = {
    ADMIN_LOGIN: 'ADMIN_LOGIN',
};
export const userLogin = {
    USER_LOGIN: 'USER_LOGIN',
};
export const warehouseLogin = {
   WAREHOUSE_LOGIN: 'WAREHOUSE_LOGIN',
};
export const requestEquipmentFetch = {
   REQUEST_EQUIPMENT_FETCH: 'REQUEST_EQUIPMENT_FETCH',
};
export const adminRegister = {
    ADMIN_REGISTER: 'ADMIN_REGISTER',
};
export const addUser = {
   ADD_USER: 'ADD_USER',
};
export const createGroup = {
   CREATE_GROUP: 'CREATE_GROUP',
};
export const createUnit = {
   CREATE_Unit: 'CREATE_Unit',
};
export const createWarehouse = {
   CREATE_WAREHOUSE: 'CREATE_WAREHOUSE',
};
export const createCategory = {
   CREATE_CATEGORY: 'CREATE_CATEGORY',
};
export const reconcileProduct = {
   RECONCILE_PRODUCT: 'RECONCILE_PRODUCT',
};
export const createProduct = {
   CREATE_PRODUCT: 'CREATE_PRODUCT',
};
export const assignProduct = {
   ASSIGN_PRODUCT: 'ASSIGN_PRODUCT',
};
export const UserEquipmentListGetS = {
   USER_EQUIPMENT_LISTGET: 'USER_EQUIPMENT_LISTGET',
};
export const unitList = {
    UNIT_LIST: 'UNIT_LIST',
};
export const roleView = {
   ROLE_VIEW: 'ROLE_VIEW',
};
export const assignProductById = {
   ASSIGN_PRODUCT_BY_ID: 'ASSIGN_PRODUCT_BY_ID',
};
export const assignUserToGroup = {
   ASSIGN_USER_TO_GROUP: 'ASSIGN_USER_TO_GROUP',
};
export const assignUserToUnit = {
   ASSIGN_USER_TO_UNIT: 'ASSIGN_USER_TO_UNIT',
};
export const moveUserToGroup = {
   MOVE_USER_TO_GROUP: 'MOVE_USER_TO_GROUP',
};
export const moveUserToUnit = {
   MOVE_USER_TO_UNIT: 'MOVE_USER_TO_UNIT',
};
export const createRoleUser = {
   CREATE_ROLE_USER: 'CREATE_ROLE_USER',
};
export const moveGrouptoUnit = {
   MOVE_GROUPTO_UNIT: 'MOVE_GROUPTO_UNIT',
};
export const deleteUnit = {
   DELETE_Unit: 'DELETE_Unit',
};
export const unitUserListGet = {
   UNIT_USER_LISTGET: 'UNIT_USER_LISTGET',
};
export const productList= {
  PRODUCT_LIST: 'PRODUCT_LIST',
};
export const groupList = {
   GROUP_LIST: 'GROUP_LIST',
};
export const userList = {
   USER_LIST: 'USER_LIST',
};
export const categoryList = {
   CATEGORY_LIST: 'CATEGORY_LIST',
};
export const warehouseList = {
   WAREHOUSE_LIST: 'WAREHOUSE_LIST',
};
export const productView = {
   PRODUCT_VIEW: 'PRODUCT_VIEW',
};
export const userPermissionView = {
   USER_PERMISSION_VIEW: 'USER_PERMISSION_VIEW',
};
export const productPut = {
   PRODUCT_PUT: 'PRODUCT_PUT',
};
export const userProductFetch = {
   USER_PRODUCT_FETCH: 'USER_PRODUCT_FETCH',
};
export const userSelectedList = {
   USER_SELECTED_LIST: 'USER_SELECTED_LIST',
};
export const ByGroupUserListGet = {
   BY_GROUP_USER_LISTGET: 'BY_GROUP_USER_LISTGET',
};
export const GroupRequestSent = {
   GROUP_REQUEST_SENT: 'GROUP_REQUEST_SENT',
};
export const updateGroupStatus = {
   UPDATE_GROUP_STATUS: 'UPDATE_GROUP_STATUS',
};
export const updateMemberStatus = {
   UPDATE_MEMBER_STATUS: 'UPDATE_MEMBER_STATUS',
};
export const MemberRequestSent = {
   MEMBER_REQUEST_SENT: 'MEMBER_REQUEST_SENT',
};
export const AddUserListGetForUNit = {
   ADD_NEW_USERLIST_GET_UNIT: 'ADD_NEW_USERLIST_GET_UNIT',
};
export const AddUserSecondListGetForUNit = {
   ADD_NEW_SECOND_USERLIST_GET_UNIT: 'ADD_NEW_SECOND_USERLIST_GET_UNIT',
};
export const NewUserRegister = {
   NEW_USER_REGISTER: 'NEW_USER_REGISTER',
};
export const newuserLogin = {
   NEW_USER_LOGIN: 'NEW_USER_LOGIN',
};
export const newuserloginTimeSendRequestForUnit = {
   NEWUSER_SENTREQUEST_FORUNIT: 'NEWUSER_SENTREQUEST_FORUNIT',
};
 
export const UserWaitingAprrovalFromUnit = {
   USER_WAITING_UNIT_LIST: 'USER_WAITING_UNIT_LIST',
};
 
export const RequestToJoinUnitForNewUserPost = {
   REQUEST_TO_JION_UNIT_FOR_NEW_USER_POST: 'REQUEST_TO_JION_UNIT_FOR_NEW_USER_POST',
};
export const MoveUserToOtherUnitListPost = {
   MOVE_USER_TO_OTHER_UNIT_LIST_POST: 'MOVE_USER_TO_OTHER_UNIT_LIST_POST',
 
};
export const SET_NEW_USER_VERIFY = "SET_NEW_USER_VERIFY";
