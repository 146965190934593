import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import styled from "styled-components";
import { ErrorMsg } from "../Global/GlobalText";
import useAppUtils, { useUnitList } from "../../utils/useAppUtils";
import {
  createUnitAction,
  NewUserLoginUnitSentRequestAction,
  setNewUserVerify,
  userDataAction,
} from "../../redux/users/action";
import {
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TableWrapper,
} from "../Pages/UniteProfile";
import GlobalSearch from "../Global/GlobalSearch";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

export default function ChoosePortal() {
  const { dispatch, navigate } = useAppUtils();
  const [isModalJoin, setModalJoin] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [requestsSent, setRequestsSent] = useState({});
  const [selectedUnitId, setSelectedUnitId] = useState(null);
  const units = useUnitList();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(5);

  const [unitName, setUnitName] = useState("");  
  const [isSubmitting, setIsSubmitting] = useState(false); 

  const userDetails = useSelector((state) => state.users.userDetails);
  const newUserVerify = useSelector((state) => state.users.is_verified);

  useEffect(() => {
    const role = localStorage.getItem("role");

    if (userDetails?.role === "groupadmin" && role === "groupadmin") {
      navigate("/dashboard");  
    } else if (userDetails?.role === "user" && newUserVerify === true) {
      navigate("/waiting-unitapproove");
    } else if (userDetails?.role === "user" && newUserVerify === false) {
      navigate("/choose_portal");
      toast.success("User verification checked.");
    }
  }, [userDetails, newUserVerify, navigate]);

  const handleRequestJoinUnit = (unitId) => {
    if (selectedUnitId && selectedUnitId !== unitId) {
      setRequestsSent((prevState) => ({
        ...prevState,
        [selectedUnitId]: false,
      }));
    }
    setRequestsSent((prevState) => ({
      ...prevState,
      [unitId]: true,
    }));
    setSelectedUnitId(unitId);
    console.log("Selected Unit ID:", unitId);
    dispatch(
      NewUserLoginUnitSentRequestAction({ unitId }, (response) => {
        if (response.status === 200) {
          dispatch(userDataAction(response.data.data.is_verified));
          navigate("/waiting-unitapproove");
        }
      })
    );
    navigate("/waiting-unitapproove");
  };
  const handleCreateUnit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);  
    dispatch(
      createUnitAction({ unit_name: unitName }, (response) => {
        setIsSubmitting(false); 
        if (response?.status === 200) {
          localStorage.setItem("token", response.data.data.token);
          localStorage.setItem("role", response.data.data.role);
          console.log("Failed to create unitdghyerfgrd",response.data.role,response.data.token );

          navigate("/dashboard");
          dispatch(setNewUserVerify(true));
          setUnitName("");
          setModalOpen(false);  
          toast.success("Unit created successfully!");
        } else if (response?.status === 400) {
          setUnitName("");
        } else {
          setUnitName("");

          console.error(response?.message || "Failed to create unit");
        }
      })
    );
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(units.length / rowsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleCloseModal = () => {
    setUnitName("");  
    setModalOpen(false); 
  };
  return (
    <Container>
      <PortalOption>
        <StyledButton onClick={() => setModalJoin(true)}>
          Send a Request to Join Unit
        </StyledButton>
      </PortalOption>
      <PortalOption>
        <StyledButton onClick={() => setModalOpen(true)}>
          Open a Unit
        </StyledButton>
      </PortalOption>
      <Modal show={isModalJoin} onHide={handleCloseModal} size="l" centered>
        <Modal.Header closeButton>
          <Modal.Title>To become a member of Unit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="search_product_div">
            <GlobalSearch search="Search for something" />
          </div>
          <TableWrapper>
            <Table>
              <thead>
                <TableRow>
                  <TableHeader>SL No</TableHeader>
                  <TableHeader>Created by</TableHeader>

                  <TableHeader>Unit Name</TableHeader>

                  <TableHeader> </TableHeader>
                </TableRow>
              </thead>
              <tbody>
                {units.map((unit, index) => (
                  <TableRow key={unit.unit_id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{unit.role || "No Role"}</TableCell>
                    <TableCell>{unit.unit_name || "No Unit"}</TableCell>
                    <TableCell>
                      <StyledReqButton
                        onClick={() => handleRequestJoinUnit(unit.unit_id)}
                        disabled={requestsSent[unit.unit_id]}
                        style={{
                          backgroundColor: requestsSent[unit.unit_id]
                            ? "green"
                            : undefined,
                        }}
                      >
                        {requestsSent[unit.unit_id]
                          ? "Request Sent"
                          : "Send Request"}
                      </StyledReqButton>
                    </TableCell>
                  </TableRow>
                ))}
              </tbody>
            </Table>
            <ErrorMsg>*YOU CAN SEND REQUEST TO ONLY ONE UNIT</ErrorMsg>
          </TableWrapper>
          <PaginationContainer>
            <StyledPaginationButton
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </StyledPaginationButton>
            {[...Array(Math.ceil(units.length / rowsPerPage))].map(
              (_, index) => (
                <StyledPageButton
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  className={index + 1 === currentPage ? "active" : ""}
                >
                  {index + 1}
                </StyledPageButton>
              )
            )}
            <StyledPaginationButton
              onClick={handleNextPage}
              disabled={currentPage === Math.ceil(units.length / rowsPerPage)}
            >
              Next
            </StyledPaginationButton>
          </PaginationContainer>
        </Modal.Body>
      </Modal>
      <Modal show={isModalOpen} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Open a Unit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleCreateUnit}>
            <Form.Group controlId="unitName">
              <Form.Label>Unit Name</Form.Label>
              <Form.Control
                type="text"
                value={unitName}
                onChange={(e) => setUnitName(e.target.value)}
                placeholder="Enter unit name"
                required
              />
            </Form.Group>
            <TableCell>
              <StyledReqButton variant="primary" type="submit">
                Create Unit
              </StyledReqButton>
            </TableCell>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #fff;
  .root .main_body .sideBar {
    display: none !important;
  }
`;

const PortalOption = styled.div`
  margin: 20px;
`;

const StyledButton = styled.button`
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(135deg, #6a11cb, #2575fc);
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;
const StyledReqButton = styled.button`
  padding: 6px 10px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(135deg, #6a11cb, #2575fc);
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }

  &:active {
    transform: translateY(0);
    background: linear-gradient(135deg, #089e0f, #69fc25);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;
const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledPaginationButton = styled.button`
  padding: 5px 10px;
  margin: 0 5px;
  background-color: #6a11cb;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #2575fc;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const StyledPageButton = styled.button`
  padding: 5px 10px;
  margin: 0 5px;
  background-color: ${(props) => (props.active ? "#2575fc" : "#fff")};
  color: ${(props) => (props.active ? "#fff" : "#1162cb")};
  border: 1px solid #1146da;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.active ? "#2575fc" : "#f1f1f1")};
  }
`;
