import React, { useEffect, useState } from "react";
import { BlueBackgroundButton } from "../Global/GlobalButtons";
import {
  FormTextBlack,
  HistoryContent,
  HistorySubContent,
} from "../Global/GlobalText";
import {
  BorderInput,
  BorderSelect,
  BorderTextarea,
} from "../Global/GlobalPlaceholder";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import imagelogo from "../../Assets/PhotoIcon.png";
import circleimage from "../../Assets/circleimage.png";
import note from "../../Assets/note.png";
import useAppUtils, {
  useCategoryList,
  useProductView,
} from "../../utils/useAppUtils";
import { ProductRoot } from "./AddProducts";
import { UPLOAD_IMAGES } from "../../utils/https";
import { toast } from "react-toastify";
import { putProductAction } from "../../redux/users/action";
import { useForm, Controller } from "react-hook-form"; // Import from react-hook-form

export const ViewProduct = () => {
  const [showInput, setShowInput] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const product = useProductView();
  const { dispatch, navigate } = useAppUtils();
  const categories = useCategoryList();
  const { handleSubmit, control, setValue, formState: { errors } } = useForm({
    defaultValues: {
      name: product?.name || "",
      categoryid: product?.categoryid || "",
    },
  });

  useEffect(() => {
    if (product) {
      setValue("name", product.name);
      setValue("categoryid", product.categoryid);
    }
  }, [product, setValue]);

  const handleImageClick = () => {
    setShowInput((prevShowInput) => !prevShowInput);
  };

  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("categoryid", values.categoryid);

    dispatch(
      putProductAction(formData, (response) => {
        if (response?.status === 200) {
          setEditMode(false);
          toast.success("Product updated successfully!");
          navigate("/warehouse_manage/product");
        } else {
          toast.error("Process failed. Please try again.");
        }
      })
    );
  };

  return (
    <ProductRoot>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="save_btn">
          {!editMode ? (
            <BlueBackgroundButton type="button" onClick={() => setEditMode(true)}>
              Update
            </BlueBackgroundButton>
          ) : (
            <BlueBackgroundButton type="submit" disabled={false}>
              Save
            </BlueBackgroundButton>
          )}
        </div>

        <div className="product_main_div">
          <div className="armycap_div">
            <div className="cap_div">
              <div className="icon_cap_head">
                <HiOutlineArrowNarrowLeft />
                <FormTextBlack>Product Name</FormTextBlack>
              </div>

              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <BorderInput
                    {...field}
                    placeholder="Product Name"
                    type="text"
                    readOnly={!editMode}
                  />
                )}
              />
              {errors.name && <div className="error">{errors.name.message}</div>}

              <div className="category_div">
                <FormTextBlack>Category Description</FormTextBlack>
                <BorderTextarea
                  placeholder={product.description}
                  readOnly
                />
              </div>

              <div className="upload_image">
                <FormTextBlack>Uploaded Image</FormTextBlack>
                <div className="upload_div">
                  {product.image && (
                    <img
                      src={`${UPLOAD_IMAGES}${product.image}`}
                      alt="Preview"
                      style={{
                        width: "150px",
                        height: "150px",
                        objectFit: "contain",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="category_main_div">
          <div className="cat_sub_div">
            <FormTextBlack>Category</FormTextBlack>
            <Controller
              name="categoryid"
              control={control}
              render={({ field }) => (
                <BorderSelect {...field} disabled={!editMode}>
                  <option value={product.category_name}>
                    {product.category_name}
                  </option>
                  {categories?.map((cat) => (
                    <option key={cat.id} value={cat.id}>
                      {cat.name}
                    </option>
                  ))}
                </BorderSelect>
              )}
            />
            {errors.categoryid && <div className="error">{errors.categoryid.message}</div>}
          </div>

          <div className="cat_sub_div">
            <FormTextBlack>Product No.</FormTextBlack>
            <BorderInput
              placeholder={product.army_cap}
              type="number"
              readOnly
            />
          </div>

          <div className="cat_sub_div">
            <FormTextBlack>SKU(Automatic)</FormTextBlack>
            <BorderInput
              placeholder={product.sku}
              type="number"
              readOnly
            />
          </div>

          <div className="cat_sub_div">
            <FormTextBlack>Warehouse</FormTextBlack>
            <BorderSelect disabled>
              <option>{product?.warehouse_name}</option>
            </BorderSelect>
          </div>
        </div>

        <div className="comments_div">
          <FormTextBlack>Comments</FormTextBlack>
          <BorderTextarea
            placeholder={product?.comments}
            readOnly
          />
        </div>

        <div className="product_history">
          <FormTextBlack>Product History</FormTextBlack>
          <div className="history_div">
            <div className="line_div">
              <img src={circleimage} alt="circleimage" />
            </div>
            <div className="cont_div">
              <img src={imagelogo} alt="imagelogo" />
              <div className="img_cont_div">
                <HistoryContent>Cap Assign to ABC Soldier</HistoryContent>
                <HistorySubContent>
                  Assign By ABC (warehouse no 8)
                </HistorySubContent>
              </div>
            </div>
            <div className="date_time_div">
              <HistoryContent>12-8-24</HistoryContent>
              <HistoryContent>10:00 AM</HistoryContent>
            </div>
            <div className="note_div">
              <img src={note} alt="note_img" onClick={handleImageClick} />
              {showInput && <BorderInput />}
            </div>
          </div>
        </div>
      </form>
      <div className="product_history">
        <FormTextBlack>Product History</FormTextBlack>
        <div className="history_div">
          <div className="line_div">
            <img src={circleimage} alt="circleimage" />
          </div>
          <div className="cont_div">
            <img src={imagelogo} alt="imagelogo" />
            <div className="img_cont_div">
              <HistoryContent>Cap Assign to ABC Soldier</HistoryContent>
              <HistorySubContent>
                Assign By ABC (warehouse no 8)
              </HistorySubContent>
            </div>
          </div>

          <div className="date_time_div">
            <HistoryContent>12-8-24</HistoryContent>
            <HistoryContent>10:00 AM</HistoryContent>
          </div>

          <div className="note_div">
            <img src={note} alt="note_img" onClick={handleImageClick} />
            {showInput && <BorderInput />}
          </div>
        </div>
      </div>
    </ProductRoot>
  );
};
