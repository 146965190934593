import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  BlackBorderButton,
  BlueBorderContent,
  TabButton,
} from "../Global/GlobalButtons";
import GlobalTable from "../Global/GlobalTable";
import GlobalSearch from "../Global/GlobalSearch";
import { useAddNewUserListRequestSent, useGroupRequestSent } from "../../utils/useAppUtils";

export const History = () => {
  const [userData, setUserData] = useState([]);  
  const [selectedTab, setSelectedTab] = useState("tab1");
  const [groupData, setGroupData] = useState([]); // For Group History

  const datagetGroupHistory = useGroupRequestSent(); // Fetch Group Data
  const dataget = useAddNewUserListRequestSent(); // Data for tab 4

  useEffect(() => {
    if (selectedTab === "tab2" && datagetGroupHistory) {
      setGroupData(
        datagetGroupHistory.map((item, index) => {
          const formattedDate = new Date(item.create_date).toISOString().split("T")[0];
          const lastunit = new Date(item.update_date).toISOString().split("T")[0];

          return {
            srNo: index + 1,
            JoinDateTime: formattedDate,
            GroupManagerName: item.status,
            CurrentUnit: <BlackBorderButton>{item.unit_name}</BlackBorderButton>,
            GroupStrength: item.total_users_in_group || 0,
            LeftDateTime: lastunit || "N/A",
            LastUnit: <BlackBorderButton>{item.lastunit_name}</BlackBorderButton>,
            LastReportingManager: item.requestBy_role,
          };
        })
      );
    }
  }, [selectedTab, datagetGroupHistory]);

  useEffect(() => {
    if (selectedTab === "tab1" && dataget) {
      setUserData(
        dataget.map((item, index) => {
          const formattedDate = new Date(item.create_date).toISOString().split("T")[0];

          return {
            srNo: index + 1,
            JoinDateTime: formattedDate,
            Position: item.user_role,
            userName: item.user_name,
            CurrentUnit: <BlackBorderButton>{item.unit_name}</BlackBorderButton>,
            PreviousUnit: <BlueBorderContent>{item.lastunit_name}</BlueBorderContent>,
            LeftDateTime: formattedDate || "N/A",
            Statuss: item.status || "no status",
          };
        })
      );
    }
  }, [selectedTab, dataget]);

  const userColumns = [
    { header: "Sr.No", accessor: "srNo" },
    { header: "Join Date & Time", accessor: "JoinDateTime" },
    { header: "User Position", accessor: "Position" },
    { header: "User Name", accessor: "userName" },
    { header: "User Requested for this Unit", accessor: "CurrentUnit" },
    { header: "User Last Unit Name", accessor: "PreviousUnit" },
    { header: "Status", accessor: "Statuss" },
    { header: "Left Date & Time", accessor: "LeftDateTime" },
  ];

  const groupColumns = [
    { header: "Sr.No", accessor: "srNo" },
    { header: "Join Date & Time", accessor: "JoinDateTime" },
    { header: "Current Unit", accessor: "CurrentUnit" },
    { header: "Group Strength", accessor: "GroupStrength" },
    { header: "Left Date & Time", accessor: "LeftDateTime" },
    { header: "Last Unit", accessor: "LastUnit" },
    { header: "Status", accessor: "GroupManagerName" },
    { header: "Last Reporting Manager", accessor: "LastReportingManager" },
  ];

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <Root>
      {/* Search Bar */}
      <div style={{ padding: "0px 20px" }}>
        <GlobalSearch search="Search for Something" />
      </div>

      {/* Tab Buttons */}
      <div className="tab_div">
        <TabButton
          className={selectedTab === "tab1" ? "selected" : ""}
          onClick={() => handleTabClick("tab1")}
        >
          User History
        </TabButton>
        <TabButton
          className={selectedTab === "tab2" ? "selected" : ""}
          onClick={() => handleTabClick("tab2")}
        >
          Group History
        </TabButton>
      </div>

      {/* Table Section */}
      <div className="table">
        {selectedTab === "tab1" ? (
          <>
            {userData.length > 0 ? (
              <GlobalTable columns={userColumns} data={userData} />
            ) : (
              <div className="no-data-container">
                <div className="icon">🚫</div>
                <h2>No Data Available</h2>
                <div className="line"></div>
                <p>We couldn’t find any data to display. Please try again later.</p>
              </div>
            )}
          </>
        ) : (
          <>
            {/* <GlobalTable columns={groupColumns} data={[]} /> */}
            {groupData.length > 0 ? (
              <GlobalTable columns={groupColumns} data={groupData} />
            ) : (
              <div className="no-data-container">
                <div className="icon">🚫</div>
                <h2>No Data Available</h2>
                <div className="line"></div>
                <p>We couldn’t find any data to display. Please try again later.</p>
              </div>
            )}
          </>
        )}
      </div>
    </Root>
  );
};

const Root = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background-color: #fff;
  border-radius: 20px;

  .tab_div {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    padding: 20px 20px 0px;
  }

  .no-data-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 87; /* Full-screen height */
    text-align: center;
    background-color: #f9f9f9; /* Light background */
    color: #555; /* Subtle text color */
    margin-top: 25px;
    border: 1px solid blue;
  }

  .no-data-container h2 {
    font-size: 2rem;
    margin: 0.5rem 0;
    color: #333; /* Primary text color */
  }

  .no-data-container p {
    font-size: 1rem;
    margin: 0.5rem 0;
    color: #777; /* Secondary text color */
  }

  .no-data-container .icon {
    font-size: 3rem;
    color: #bbb; /* Icon color */
    margin-bottom: 1rem;
  }

  .no-data-container .line {
    width: 80px;
    height: 2px;
    background-color: #ddd; /* Divider line color */
    margin: 1rem 0;
  }
  .table {
    width: 100%;
    height: 100%;
    @media (max-width: 600px) {
      padding: 0;
    }
  }
`;
 
