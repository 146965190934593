import React, { useState } from "react";
import styled from "styled-components";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { FormTextBlack, UploadContent } from "../Global/GlobalText";
import { BorderInput, BorderTextarea } from "../Global/GlobalPlaceholder";
import { BlackBorderButton } from "../Global/GlobalButtons";
import GlobalSearch from "../Global/GlobalSearch";
import GlobalTable from "../Global/GlobalTable";
import { useUserProductFetch } from "../../utils/useAppUtils";
 

export const WarehouseManage = () => {
  const userProductFetch= useUserProductFetch();
  console.log("userProductFetch",userProductFetch);
  const [data, setData] = useState([
    {
      slNo: 1,
      image: "CapIcon",
      productname: "Army Cap1",
      assignedto: "Warehouse 9",
      selected: false,
      buttonType: "inside",
    },
    {
      slNo: 2,
      image: "CapIcon",
      productname: "Army Cap1",
      assignedto: "Warehouse 9",
      selected: false,
      buttonType: "outside",
    },
  ]);

  const columns = [
    { header: "Select", accessor: "select" },
    { header: "SL No", accessor: "slNo" },
    { header: "Image", accessor: "image" },
    { header: "Product Name", accessor: "productname" },
    {
      header: "Location",
      accessor: "location",
      render: (rowIndex) => {
        const row = data[rowIndex];
        return (
          <>
            {row.buttonType === "inside" && (
              <div className="in_out_btn">
                <div
                  className="inside_btn"
                  onClick={() => handleLocationClick(rowIndex)}
                >
                  Inside
                </div>
              </div>
            )}
            {row.buttonType === "outside" && (
              <div className="in_out_btn">
                <div
                  className="outside_btn"
                  onClick={() => handleOutsideButtonClick(rowIndex)}
                >
                  Outside
                </div>
              </div>
            )}
          </>
        );
      },
    },
    { header: "Assigned to", accessor: "assignedto" },
  ];

  const handleSelectChange = (rowIndex) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[rowIndex].selected = !newData[rowIndex].selected;
      return newData;
    });
  };

  const handleLocationClick = (rowIndex) => {
  };

  const handleOutsideButtonClick = (rowIndex) => {
  };

  return (
    <Root>
      <div className="ware_main_div">
        <div className="cap_image">
          <div className="cap_div">
            <div className="icon_cap_head">
              <HiOutlineArrowNarrowLeft />
              <FormTextBlack>Army Cap</FormTextBlack>
            </div>

            <BorderInput placeholder="Army Cap"></BorderInput>

            <div className="category_div">
              <FormTextBlack>Category Description</FormTextBlack>
              <BorderTextarea placeholder="Add Product Description Here"/>
                {" "}
           
            </div>
          </div>

          <div className="upload_image">
            <FormTextBlack>Upload Image</FormTextBlack>
            <div className="upload_div">
              <div className="upload_btn">
                <BlackBorderButton>Upload New</BlackBorderButton>
                <input type="file" className="file_input" />
              </div>
              <UploadContent>Add Your Images Here</UploadContent>
            </div>
          </div>
        </div>
      </div>

      <ProductDiv className="product_div">
        <div className="product_btn">
          <FormTextBlack>Product List</FormTextBlack>
          <BlackBorderButton>Add Now</BlackBorderButton>
        </div>
        <div className="search_product_div">
          <GlobalSearch search="Search Product" />
        </div>

        <div className="table_div">
          <GlobalTable
            columns={columns}
            data={data}
            onSelectChange={handleSelectChange}
          />
        </div>
      </ProductDiv>
    </Root>
  );
};

const Root = styled.section`
  display: flex;
  gap: 20px;
  flex-direction: column;
  .ware_main_div {
    background-color: #ffffff;
    border-radius: 25px;
    padding: 20px;
    .cap_image {
      display: flex;
      gap: 20px;
      @media (max-width: 567px) {
        flex-wrap: wrap;
      }
      .cap_div {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 55%;
        @media (max-width: 567px) {
          width: 100%;
        }

        .icon_cap_head {
          display: flex;
          gap: 10px;
          align-items: center;
        }

        .category_div {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
      }
      .upload_image {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 45%;
        @media (max-width: 567px) {
          width: 100%;
        }
         
        .upload_div {
          background-color: #f0f0f0;
          padding: 30px;
          display: flex;
          flex-direction: column;
          gap: 5px;
          align-items: center;
          border-radius: 25px;
          .upload_btn {
            display: flex;
            justify-content: center;
            position: relative;
            .file_input {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
`;
export const ProductDiv = styled.div`
  background-color: #ffffff;
  border-radius: 25px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .product_btn {
    display: flex;
    justify-content: space-between;
  }
  .search_product_div {
    width: 70%;
    @media (max-width: 567px) {
      width: 100%;
    }
  }

  .table_div {
    @media (max-width: 567px) {
      overflow: auto;
    }
  }

  .in_out_btn {
    display: flex;
    justify-content: center;
    .inside_btn {
      border: 1px solid #1814f3;
      border-radius: 50px;
      background: #ffffff;
      color: #1814f3;
      font-size: 15px;
      font-weight: 500;
      line-height: 18.15px;
      cursor: pointer;
      width: 100px;
      padding: 5px 10px;
    }

    .outside_btn {
      border: 1px solid #000000;
      border-radius: 50px;
      background: #ffffff;
      color: #000000;
      font-size: 15px;
      font-weight: 500;
      line-height: 18.15px;
      cursor: pointer;
      width: 100px;
      padding: 5px 10px;
    }
  }
`;
