import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TabButton } from "../Global/GlobalButtons";
import GlobalSearch from "../Global/GlobalSearch";
import GlobalTable from "../Global/GlobalTable";
import useAppUtils, {
  useAddNewUserListRequestSent,
  useSecondAddNewUserListRequestSent,
} from "../../utils/useAppUtils";
import {
  MoveUserToOtherUnitListPostAction,
  RequestToJoinUnitForNewUserPostAction,
} from "../../redux/users/action";
import { toast } from "react-toastify";
import { BorderSelect } from "../Global/GlobalPlaceholder";

const RequestUnit = () => {
  const [selectedTab, setSelectedTab] = useState("tab3");
  const [dataTab3, setDataTab3] = useState([]);
  const [dataTab4, setDataTab4] = useState([]);
  const [loading, setLoading] = useState(false);
  const tab3Data = useSecondAddNewUserListRequestSent(); // Tab 3 data
  const tab4Data = useAddNewUserListRequestSent(); // Tab 4 data
  const { dispatch, navigate } = useAppUtils();
  useEffect(() => {
    setLoading(true);
    if (selectedTab === "tab3") {
      setDataTab3(tab3Data);
    } else if (selectedTab === "tab4") {
      setDataTab4(tab4Data);
    }
    setLoading(false);
  }, [selectedTab, tab3Data, tab4Data]);
  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };
  const handleTab3StatusChange = (e, item) => {
    const updatedStatus = e.target.value;
    updateDataState(
      dataTab3,
      setDataTab3,
      item.addnewuser_id,
      updatedStatus,
      UpdateAddMemberStatus
    );
  };
  const handleTab4StatusChange = (e, item) => {
    const updatedStatus = e.target.value;
    updateDataState(
      dataTab4,
      setDataTab4,
      item.usermove_id,
      updatedStatus,
      UpdateMoveMemberStatus
    );
  };
  const updateDataState = (data, setData, id, updatedStatus, apiCall) => {
    const updatedData = data.map((item) =>
      item.addnewuser_id === id || item.usermove_id === id
        ? { ...item, status: updatedStatus }
        : item
    );
    setData(updatedData);
    apiCall(id, updatedStatus);
  };

  const UpdateMoveMemberStatus = (id, status) => {
    dispatch(
      MoveUserToOtherUnitListPostAction({ id, status }, (response) => {
        if (response?.status === 200) {
          toast.success("Status updated successfully!");
          navigate("/dashboard");
        } else {
          toast.error("Process failed. Please try again.");
        }
      })
    );
  };

  const UpdateAddMemberStatus = (id, status) => {
    dispatch(
      RequestToJoinUnitForNewUserPostAction({ id, status }, (response) => {
        if (response?.status === 200) {
          toast.success("Status updated successfully!");
          navigate("/dashboard");
        } else {
          toast.error("Process failed. Please try again.");
        }
      })
    );
  };

  const commonColumns = [
    { header: "SL No", accessor: "slNo" },
    { header: "Date & Time", accessor: "datetime" },
    { header: "Unit Requested", accessor: "unit_name" },
    { header: "Requested User", accessor: "username" },
    { header: "Requested Role", accessor: "userole" },
    { header: "Unit Change Request By", accessor: "creatorname" },
    { header: "Status", accessor: "status" },
    { header: "Requested User Last Unit", accessor: "lastunitname" },
  ];

  return (
    <Root>
      <div className="tab_div">
        <TabButton
          className={selectedTab === "tab3" ? "selected" : ""}
          onClick={() => handleTabChange("tab3")}
        >
          Request to Join Unit (For New User)
        </TabButton>
        <TabButton
          className={selectedTab === "tab4" ? "selected" : ""}
          onClick={() => handleTabChange("tab4")}
        >
          Move User to Other Unit List
        </TabButton>
      </div>

      <div className="search_main_div">
        <GlobalSearch search="Search for something" />
      </div>

      <div className="table">
        {loading ? (
          <p>Loading...</p>
        ) : selectedTab === "tab3" ? (
          <GlobalTable
            columns={commonColumns}
            data={dataTab3.map((item) => {
              console.log("lastunit_name:", item.lastunit_name); // Check the value in the console

              return {
                ...item,
                slNo: item.addnewuser_id,
                datetime: new Date(item.create_date).toLocaleDateString(),
                status: (
                  <BorderSelect
                    value={item.status}
                    onChange={(e) => handleTab3StatusChange(e, item)}
                  >
                    <option value="pending">Pending</option>
                    <option value="approved">Approved</option>
                    <option value="declined">Declined</option>
                  </BorderSelect>
                ),
                username: item.user_name,
                userole: item.user_role, // Convert user_role to uppercase
                creatorname: item.creator_name || "N/A",
                lastunitname: (
                  <div
                    style={{
                      backgroundColor:
                        item.lastunit_name.trim() === "New User Request"
                          ? "red"
                          : "transparent",
                      color:
                        item.lastunit_name.trim() === "New User Request"
                          ? "white"
                          : "inherit",
                      padding: "2px 4px",
                      borderRadius: "4px",
                      fontWeight: "600",
                    }}
                  >
                    {item.lastunit_name}
                  </div>
                ),
              };
            })}
          />
        ) : (     
          <GlobalTable
            columns={commonColumns}
            data={dataTab4.map((item) => ({
              ...item,
              slNo: item.usermove_id,
              datetime: new Date(item.create_date).toLocaleDateString(),
              status: (
                <BorderSelect
                  value={item.status}
                  onChange={(e) => handleTab4StatusChange(e, item)}
                >
                  <option value="pending">Pending</option>
                  <option value="approved">Approved</option>
                  <option value="declined">Declined</option>
                </BorderSelect>
              ),
              username: item.user_name,
              userole: item.user_role, // Convert user_role to uppercase
              creatorname: item.creator_name || "N/A",
              lastunitname: item.lastunit_name,
            }))}
          />
        )}
      </div>
    </Root>
  );
};
export default RequestUnit;

const Root = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;

  .tab_div {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }

  .button_div {
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  .search_main_div {
    padding: 20px 50px;
  }

  .table_div {
    margin-top: 30px;
    .table {
      width: 100%;
      height: 100%;
      margin-top: 20px;
    }
  }
`;
